import React from "react";
import { css } from "@emotion/react";
import Banner from "../../resources/About/about_banner.png";
import { ReactComponent as ResponsibilityIcon } from "../../resources/About/img_responsibility.svg";
import { ReactComponent as InnovationIcon } from "../../resources/About/img_innovation.svg";
import { ReactComponent as ExpertiseIcon } from "../../resources/About/img_expertise.svg";
import { ReactComponent as BiLogo } from "../../resources/bi_logo.svg";

import { ReactComponent as TotalIcon } from "../../resources/About/total_icon.svg";
import { ReactComponent as BusinessIcon } from "../../resources/About/business_icon.svg";
import { ReactComponent as DynamicIcon } from "../../resources/About/dynamic_icon.svg";
import { ReactComponent as DigitalIcon } from "../../resources/About/digital_icon.svg";

import { ReactComponent as FocusLogo } from "../../resources/About/bi_tmax_focus.svg";
import { ReactComponent as ForteLogo } from "../../resources/About/bi_tmax_forte.svg";
import { ReactComponent as SolomonLogo } from "../../resources/About/bi_tmax_solomon.svg";
import { ReactComponent as ProjectLogo } from "../../resources/About/bi_tmax_fortepj.svg";

import { ReactComponent as SuperItamImg } from "../../resources/About/img_superitam.svg";

const About = () => {
  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div css={wrapperStyle}>
        <div css={imgWrapper}>
          <div className="img-center">
            <img src={Banner} />
          </div>
          <div css={textWrapper}>
            <span>회사 소개</span>
          </div>
        </div>
        <div css={BizAIWrapper}>
          <span className="sub-title">The 1st Digital Transformation Partner</span>
          <span className="title">
            <BiLogo
              css={css`
                width: 244px;
                height: 32px;
              `}
            />
          </span>

          <div className="logo-wrapper">
            <div
              css={[
                BizCardWrapper,
                css`
                  background: #182f69;
                `,
              ]}
            >
              <span className="card-title">Expertise</span>
              <span className="card-content">
                기업의 진보와 혁신을 이뤄내기 위해 필요한
                <br />
                비즈니스 소프트웨어를 개발하는 IT 전문 기업
              </span>
              <div className="icon-wrapper">
                <ExpertiseIcon />
              </div>
            </div>
            <div
              css={[
                BizCardWrapper,
                css`
                  background: #4e3280;
                `,
              ]}
            >
              <span className="card-title">Innovation</span>
              <span className="card-content">
                다양한 산업 분야의 지속적인
                <br />
                Data Transformation에 대한 니즈 증대,
                <br />그 가운데에서 고객의 DT성공에 필요한
                <br />
                모든 서비스 제공
              </span>
              <div className="icon-wrapper">
                <InnovationIcon />
              </div>
            </div>
            <div
              css={[
                BizCardWrapper,
                css`
                  background: #591464;
                `,
              ]}
            >
              <span className="card-title">Responsibility</span>
              <span className="card-content">
                기업이 꿈꾸는 더 밝은 미래를 위한
                <br />
                지속적인 투자와 연구
              </span>
              <div className="icon-wrapper">
                <ResponsibilityIcon />
              </div>
            </div>
          </div>
        </div>

        <div css={SolutionWrapper}>
          <div className="title">혁신적인 슈퍼앱 솔루션</div>
          <div className="sub-title">
            TmaxBizAI는 급변하는 IT 환경에 빠르게 적응하고 기업이 필요로 하는 서비스와
            <br />
            신기술을 쉽게 도입할 수 있는 슈퍼앱 기반의 새로운 B2B 서비스를 제공합니다.
          </div>
          <div className="icons-container">
            <div css={SolutionIconWrapper}>
              <TotalIcon
                css={css`
                  &:hover {
                    rect:nth-of-type(1) {
                      fill: #76a9fa;
                    }
                    rect:nth-of-type(2) {
                      fill: #c3ddfd;
                    }
                    rect:nth-of-type(3) {
                      fill: #76a9fa;
                    }
                    rect:nth-of-type(4) {
                      fill: #1c64f2;
                    }
                  }
                  g > rect {
                    transition: all ease 0.5s;
                  }
                `}
              />
              <div className="title">Total Business Platform</div>
            </div>
            <div css={SolutionIconWrapper}>
              <DynamicIcon
                css={css`
                  &:hover {
                    path:nth-of-type(1) {
                      fill: #c3ddfd;
                    }
                    rect:nth-of-type(2) {
                      fill: #76a9fa;
                    }
                    rect:nth-of-type(3) {
                      fill: #1a56db;
                    }
                  }
                  g > rect,
                  path {
                    transition: all ease 0.5s;
                  }
                `}
              />
              <div className="title">Dynamic Collaboration</div>
            </div>
            <div css={SolutionIconWrapper}>
              <BusinessIcon
                css={css`
                  &:hover {
                    path:nth-of-type(1) {
                      fill: #1c64f2;
                    }
                    path:nth-of-type(2) {
                      fill: #e1effe;
                    }
                    path:nth-of-type(3) {
                      fill: #76a9fa;
                    }
                    path:nth-of-type(4) {
                      fill: #c3ddfd;
                    }
                  }
                  g > path {
                    transition: all ease 0.3s;
                  }
                `}
              />
              <div className="title">Business Insight</div>
            </div>
            <div css={SolutionIconWrapper}>
              <DigitalIcon
                css={css`
                  &:hover {
                    rect:nth-of-type(1) {
                      fill: #76a9fa;
                    }
                    rect:nth-of-type(2) {
                      fill: #1a56db;
                    }
                    path:nth-of-type(3) {
                      fill: #c3ddfd;
                    }
                    path:nth-of-type(4) {
                      fill: #1c64f2;
                    }
                  }
                  g > path,
                  rect {
                    transition: all ease 0.3s;
                  }
                `}
              />
              <div className="title">Digital Transformation Partner</div>
            </div>
          </div>

          <div className="product-icon-wrapper">
            <div className="main-logo">
              <BiLogo
                css={css`
                  width: 204px;
                  height: 37px;
                `}
              />
              <div className="product-intro">B2B IT Solution</div>
            </div>

            <div className="product-logo">
              <div css={ProduuctLogWrapper}>
                <FocusLogo />
                <div className="logo-intro">차세대 AI 고객 관리 솔루션</div>
              </div>
              <div css={ProduuctLogWrapper}>
                <SolomonLogo />
                <div className="logo-intro">모두를 위한 통합 AI 솔루션</div>
              </div>
              <div css={ProduuctLogWrapper}>
                <ForteLogo />
                <div className="logo-intro">확장・연동이 뛰어난 ERP</div>
              </div>
              <div css={ProduuctLogWrapper}>
                <ProjectLogo />
                <div className="logo-intro">Dynamic 협업 공간</div>
              </div>
            </div>
          </div>
        </div>

        <div css={B2BServiceWrapper}>
          <div className="title-wrapper">
            <div className="title">Full-Stack B2B Service</div>
            <div className="sub-title">
              {window.outerWidth > 375 ? (
                <>
                  TmaxBizAI는 슈퍼앱 기술을 바탕으로
                  <br />
                  Digital Transformation에 필요한
                  <br />
                  핵심 B2B 서비스를 Full-Stack으로 제공합니다.
                </>
              ) : (
                <>
                  TmaxBizAI는 슈퍼앱 기술을 바탕으로
                  <br />
                  Digital Transformation에 필요한 핵심
                  <br />
                  B2B 서비스를 Full-Stack으로 제공합니다.
                </>
              )}
            </div>
          </div>

          <div className="img-area">
            <div className="chart-area">
              <div className="chart-wrapper-first">
                <div className="chart-content-container_first">App Service</div>
                <div className="chart-content-container_second">App Platform</div>
                <div className="chart-content-container_third">System</div>
              </div>
              <div className="chart-wrapper-sub">
                <div
                  css={css`
                    position: absolute;
                    top: 0;
                    left: 50%;
                    display: inline-block;
                    background: white;
                    transform: translate(-50%, 0%);

                    @media only screen and (max-width: 375px) {
                      top: -5px;
                    }
                  `}
                >
                  <BiLogo
                    css={css`
                      @media only screen and (max-width: 375px) {
                        width: 92px;
                        height: 16px;
                      }
                    `}
                  />
                </div>
                <div className="container-first">
                  <div className="card">ERP</div>
                  <div className="card">CRM</div>
                  <div
                    className="card"
                    css={css`
                      padding: 0px !important;
                    `}
                  >
                    DSS
                    <span
                      css={css`
                        color: #1c64f2;

                        font-family: Pretendard;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;

                        @media only screen and (max-width: 375px) {
                          display: none;
                        }
                      `}
                    >
                      Decision Support System
                    </span>
                  </div>
                </div>
                <div className="container-second">
                  <div className="container-second-block">AI</div>
                  <div className="container-second-block">Metaverse</div>
                  <div className="container-second-block">Collaboration</div>
                  <div className="container-second-block">
                    GAIA
                    <br />
                    클라우드 기반 앱 개발 솔루션
                  </div>
                </div>
                <div className="container-third">
                  <div className="container-third-block">Gateway</div>
                  <div className="container-third-block">Middleware Server</div>
                  <div className="container-third-block">DBMS (Cloud)</div>
                </div>
              </div>
            </div>
            <div className="logo-area">
              <div className="super-itam">Super ITAM</div>
              <SuperItamImg
                css={css`
                  @media only screen and (max-width: 375px) {
                    width: 267.15px;
                    height: 156px;
                  }
                `}
              />
              <span className="super-itam-context">
                기업 IT와 관련된 모든 자산들을 통합 관리할 수 있는 IT All Management 환경 제공
              </span>
            </div>
          </div>
        </div>

        <div css={ContactContainer}>
          <div className="contact-wrapper">
            <span className="contact-title">회사 정보</span>
            <div className="contact-table">
              <div className="contact-row">
                <span className="contact-key">설립일</span>
                <span className="contact-value">2020년 5월 8일</span>
              </div>
              <div className="contact-row">
                <span className="contact-key">회사명</span>
                <span className="contact-value">TmaxBizAI(티맥스비즈에이아이)</span>
              </div>
              <div className="contact-row">
                <span className="contact-key">사업 분야</span>
                <span className="contact-value">CRM, ERP, Business Intelligence, AI, Commerce</span>
              </div>
              <div className="contact-row">
                <span className="contact-key">임직원 수</span>
                <span className="contact-value">180명 (2024. 04 기준)</span>
              </div>
              <div className="contact-row">
                <span className="contact-key">소재지</span>
                <span className="contact-value">
                  경기도 성남시 분당구 황새울로 258번길 31, 예미지빌딩
                </span>
              </div>
              <div className="contact-row">
                <span className="contact-key">홈페이지</span>
                <span className="contact-value">https://www.tmaxbizai.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

const wrapperStyle = css`
  width: inherit;
  margin-top: -61px;
  background: #fff;
  text-align: center;

  @media only screen and (max-width: 375px) {
    margin-top: 0px;
  }
`;
const imgWrapper = css`
  position: relative;

  height: 350px;
  overflow: hidden;

  @media only screen and (max-width: 375px) {
    height: 120px;
  }

  .img-center {
    text-align: center;
    position: absolute;
    top: 0;
    right: -200%;
    bottom: 0;
    left: -200%;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const textWrapper = css`
  position: absolute;
  left: calc(360px + 20px);
  top: 180px;

  @media only screen and (max-width: 375px) {
    left: 20px;
    top: 43px;
  }

  span {
    color: #fff;
    font-family: Pretendard;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }
`;

const BizAIWrapper = css`
  padding: 150px 360px;
  display: flex;
  flex-flow: column;
  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
  }

  .sub-title {
    color: var(--Text-Alternative, #797e86);
    text-align: center;

    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 1rem;
    }
  }

  .title {
    color: var(--Text-Normal, #1e1e1e);
    text-align: center;
    font-family: Pretendard;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-top: 8px;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
      line-height: 160%;
    }
  }

  .logo-wrapper {
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-flow: row;
    gap: 24px;
    flex-wrap: wrap;

    justify-content: center;

    @media only screen and (max-width: 375px) {
      margin-top: 20px;
    }
  }
`;

const BizCardWrapper = css`
  display: inline-flex;
  width: 384px;
  height: 400px;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  position: relative;
  overflow: hidden;

  border-radius: 20px;
  flex-shrink: 0;
  box-sizing: border-box;

  @media only screen and (max-width: 375px) {
    padding: 24px;
    width: 335px;
    height: 180px;
  }

  .card-title {
    color: #fff;
    font-family: Pretendard;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    @media only screen and (max-width: 375px) {
      font-size: 1.25rem;
    }
  }
  .card-content {
    color: #fff;
    font-family: Pretendard;
    font-size: 0.938rem;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    text-align: start;
    z-index: 1;

    @media only screen and (max-width: 375px) {
      font-size: 0.875rem;
    }
  }
  .icon-wrapper {
    position: absolute;
    bottom: 0;
    right: 32px;
    @media only screen and (max-width: 375px) {
      right: 0;
      bottom: -5px;
      svg {
        width: 144px;
        height: 148px;
      }
    }
  }
`;

const SolutionWrapper = css`
  background: #f9fafb;
  padding: 150px 360px;
  text-align: start;
  display: flex;
  flex-flow: column;
  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
  }

  .title {
    color: #1e1e1e;
    font-family: Pretendard;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .sub-title {
    color: #1e1e1e;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    margin-top: 16px;

    @media only screen and (max-width: 375px) {
      font-size: 0.875rem;
      margin-top: 12px;
    }
  }

  .icons-container {
    background: #fff;
    padding: 16px 0px;
    margin-top: 48px;

    flex-flow: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .product-icon-wrapper {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 12px;
    background: #fff;

    margin-top: 32px;

    @media only screen and (max-width: 375px) {
      padding: 16px;
      gap: 16px;

      .main-logo {
        svg {
          width: 132px;
          height: 24px;
        }
      }
    }

    .main-logo .product-intro {
      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      @media only screen and (max-width: 375px) {
        font-size: 0.75rem;
        line-height: 140%;
        margin-top: 8px;
      }

      text-align: center;
      margin-top: 16px;
    }

    .product-logo {
      display: flex;
      flex-flow: row;
      gap: 16px;
      flex-wrap: wrap;

      @media only screen and (max-width: 375px) {
        gap: 8px;
      }
    }
  }
`;

const SolutionIconWrapper = css`
  display: flex;
  padding-top: 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 282px;
  flex-shrink: 0;
  cursor: pointer;

  @media only screen and (max-width: 375px) {
    width: 141px;
    svg {
      width: 77px;
      height: 77px;
    }
  }
  .title {
    display: flex;
    height: 80px;
    flex-direction: column;
    justify-content: center;

    color: #1e1e1e;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 0.813rem;
    }
  }
`;

const ProduuctLogWrapper = css`
  border-radius: 8px;
  border: 1px solid var(--Line-Normal, #e5e5e5);
  background: #fff;

  @media only screen and (max-width: 375px) {
    padding: 12px 10.25px;
    gap: 8px;

    width: 147.5px;
    height: 73px;
  }

  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;

  width: 276px;
  height: 120px;

  box-sizing: border-box;

  .logo-intro {
    color: #1e1e1e;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 137.5%;

    @media only screen and (max-width: 375px) {
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: -0.12px;
      line-height: 140%;
    }
  }
`;

const B2BServiceWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  align-self: stretch;
  padding: 150px 360px;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
    gap: 32px;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    @media only screen and (max-width: 375px) {
      gap: 12px;
    }

    .title {
      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      @media only screen and (max-width: 375px) {
        font-size: 1.25rem;
      }
    }
    .sub-title {
      color: #1e1e1e;
      text-align: center;
      font-family: Pretendard;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
      }
    }
  }

  .img-area {
    display: flex;
    padding: 32px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #e5e5e5;

    @media only screen and (max-width: 375px) {
      padding: 16px;
      width: 100%;
      gap: 16px;
    }

    .chart-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      flex-flow: row;

      .chart-wrapper-first {
        display: inline-flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        border-radius: 20px;

        @media only screen and (max-width: 375px) {
          padding: 18px 8px 8px 8px;
          gap: 8px;
        }

        .chart-content-container_first {
          display: flex;
          width: 240px;
          height: 132px;
          padding: 24px 60px;
          justify-content: center;
          align-items: center;

          border-radius: 16px;
          border: 1px solid #1c64f2;
          background: #fff;

          color: #1a56db;
          font-family: Pretendard;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          box-sizing: border-box;

          @media only screen and (max-width: 375px) {
            width: 100%;
            padding: 8px;
            font-size: 0.625rem;
            height: 56px;
            border-radius: 6px;
          }
        }
        .chart-content-container_second {
          display: flex;
          width: 240px;
          height: 240px;
          padding: 102px 60px;
          justify-content: center;
          align-items: center;
          gap: 10px;

          border-radius: 16px;
          background: var(--Primary-Normal, #1c64f2);

          color: #fff;
          font-family: Pretendard;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          box-sizing: border-box;

          @media only screen and (max-width: 375px) {
            width: 100%;
            padding: 8px;
            font-size: 0.625rem;
            height: 88px;
            border-radius: 6px;
          }
        }
        .chart-content-container_third {
          display: flex;
          width: 240px;
          height: 84px;
          padding: 24px 60px;
          justify-content: center;
          align-items: center;
          gap: 10px;

          border-radius: 16px;
          background: var(--Primary-Normal, #1c64f2);

          color: #fff;
          font-family: Pretendard;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          box-sizing: border-box;

          @media only screen and (max-width: 375px) {
            width: 100%;
            height: 40px;
            padding: 8px;
            font-size: 0.625rem;
            border-radius: 6px;
          }
        }
      }

      .chart-wrapper-sub {
        display: flex;
        flex-flow: column;
        padding-top: 24px;
        position: relative;

        @media only screen and (max-width: 375px) {
          padding-top: 8px;
        }

        .container-first {
          display: inline-flex;
          padding: 24px;
          align-items: flex-start;
          gap: 24px;
          border-radius: 20px;
          border: 2px dashed #1c64f2;

          @media only screen and (max-width: 375px) {
            padding: 8px;
            gap: 8px;
            border-radius: 6px;
            border: 0.942px dashed #1c64f2;
          }

          .card {
            display: flex;
            width: 240px;
            height: 84px;
            padding: 24px 60px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-flow: column;

            border-radius: 16px;
            border: 1px solid #1c64f2;
            background: #fff;

            color: #1a56db;
            font-family: Pretendard;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            box-sizing: border-box;

            @media only screen and (max-width: 375px) {
              padding: 8px;
              width: 67px;
              height: 40px;

              font-size: 0.625rem;
              border-radius: 6px;

              border: 0.471px solid #1c64f2;
            }
          }
        }

        .container-second {
          display: flex;
          width: 816px;
          padding: 24px 24px 0px 24px;
          align-items: flex-start;
          align-content: flex-start;
          gap: 24px;
          flex-wrap: wrap;
          border-radius: 20px;

          @media only screen and (max-width: 375px) {
            width: 233px;
            padding: 8px;
            gap: 8px;
          }

          .container-second-block {
            display: flex;
            width: 240px;
            height: 108px;
            padding: 24px 60px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 16px;
            background: var(--Primary-Soft, #ebf5ff);

            color: var(--Primary-Dark, var(--Blue-950, #001536));
            font-family: Pretendard;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;

            &:last-child {
              width: 100%;
            }

            @media only screen and (max-width: 375px) {
              width: 67px;
              padding: 4px;
              height: 40px;

              font-size: 0.625rem;
              border-radius: 6px;
            }
          }
        }

        .container-third {
          display: inline-flex;
          padding: 24px;
          align-items: flex-start;
          gap: 24px;

          border-radius: 20px;

          @media only screen and (max-width: 375px) {
            padding: 0px 8px;
            gap: 8px;
          }

          .container-third-block {
            display: flex;
            width: 240px;
            height: 84px;
            padding: 24px 60px;
            justify-content: center;
            align-items: center;
            gap: 10px;

            border-radius: 16px;
            background: var(--Primary-Soft, #ebf5ff);

            color: var(--Primary-Dark, var(--Blue-950, #001536));
            font-family: Pretendard;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;

            @media only screen and (max-width: 375px) {
              width: 67px;
              padding: 4px;
              height: 40px;

              font-size: 0.625rem;
              border-radius: 6px;
            }
          }
        }
      }
    }
    .logo-area {
      display: flex;
      width: 1104px;
      padding: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 32px;

      border-radius: 20px;
      background: rgba(235, 245, 255, 0.5);

      @media only screen and (max-width: 375px) {
        width: 303px;
        border-radius: 12px;
      }

      .super-itam {
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 999px;
        background: var(--Blue-950, #001536);

        color: #fff;

        text-align: center;
        font-family: Pretendard;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;

        @media only screen and (max-width: 375px) {
          padding: 4px 12px;
          font-size: 0.875rem;
        }
      }

      .super-itam-context {
        color: var(--Text-Normal, #1e1e1e);

        font-family: Pretendard;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;

        @media only screen and (max-width: 375px) {
          font-size: 0.875rem;
          font-weight: 400;
        }
      }
    }
  }
`;

const ContactContainer = css`
  display: flex;
  padding: 150px 360px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
  }

  .contact-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    @media only screen and (max-width: 375px) {
      flex-flow: column;
      justify-content: center;
      gap: 32px;
    }

    .contact-title {
      color: var(--Text-Normal, #1e1e1e);
      font-family: Pretendard;
      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      @media only screen and (max-width: 375px) {
        font-size: 1.25rem;
      }
    }

    .contact-table {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      width: 800px;
      border-top: 2px solid var(--Text-Normal, #1e1e1e);

      @media only screen and (max-width: 375px) {
        width: 335px;
      }

      .contact-row {
        display: flex;
        align-items: center;
        height: 72px;
        border-bottom: 1px solid #e5e5e5;
        gap: 120px;
        width: 100%;

        @media only screen and (max-width: 375px) {
          gap: 12px;
        }

        .contact-key {
          width: 120px;

          color: var(--Text-Alternative, #4b5563);
          font-family: Pretendard;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: -0.16px;

          @media only screen and (max-width: 375px) {
            font-size: 0.875rem;
            width: 60px;
          }
        }
        .contact-value {
          color: var(--Text-Normal, #1e1e1e);
          width: calc(100% - 120px);

          font-family: Pretendard;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: -0.16px;
          text-align: start;

          @media only screen and (max-width: 375px) {
            font-size: 0.875rem;
            width: calc(100% - 60px);
          }
        }
      }
    }
  }
`;

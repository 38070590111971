import React, { useState } from "react";
import { css } from "@emotion/react";
import { useParams, useNavigate } from "react-router-dom";
import { ReactComponent as MacBookAir } from "../../resources/Announcement/MacBookAir.svg";
import { ReactComponent as BIBloack } from "../../resources/Announcement/TmaxBizAI_Black.svg";
import { ReactComponent as ExpertiseIcon } from "../../resources/About/img_expertise.svg";
import { ReactComponent as InnovationIcon } from "../../resources/About/img_innovation.svg";
import { ReactComponent as ResponsibilityIcon } from "../../resources/About/img_responsibility.svg";
import { ReactComponent as ThumbUpIcon } from "../../resources/Announcement/ThumbUpIcon.svg";

const AnnouncementDetail = ({ contentList }) => {
  const history = useNavigate();

  const { id } = useParams();

  const [select, setSelect] = useState({ title: "", date: "" });

  React.useEffect(() => {
    const temp = contentList.find((o) => o.id === Number(id));
    setSelect({ title: temp.title, date: temp.date });
  }, []);

  return (
    <>
      <div css={ContentContainer}>
        <div className="content-title-area">
          <div className="content-title">{select.title}</div>
          <div className="content-date">{select.date}</div>
        </div>
        <div className="content-area">
          <div className="content-summary">
            {
              "TmaxBizAI 상품 안내와 공지에 대한 소식을 전해드립니다.\r\nTmaxBizAI의 대표 상품을 자세히 만나보세요."
            }
          </div>
          <div css={BannerStyle}>
            <div className="gradient-area" />
            <div className="top-circle" />
            <div className="right-circle" />
            <div className="content-area">
              <div className="title-text">Welcome to</div>
              <div className="title-text">
                <BIBloack
                  css={
                    window.outerWidth > 375
                      ? css`
                          margin-right: 16px;
                        `
                      : css`
                          width: 129px;
                          height: 17px;
                          margin-right: 8px;
                        `
                  }
                />
                {"Homepage"}
              </div>
              <MacBookAir
                css={
                  window.outerWidth > 375
                    ? css``
                    : css`
                        width: 200px;
                        height: 125px;
                        margin-top: 30px;
                        margin-left: 20px;
                      `
                }
              />
              <div className="sub-text">
                {window.outerWidth > 375
                  ? "안녕하세요, TmaxBizAI 홈페이지에 오신 것을 환영합니다.\r\nTmaxBizAI는 SuperApp 솔루션을 통해 기업의 미래를 함께 열어가는 IT 리더입니다."
                  : "안녕하세요,\r\nTmaxBizAI 홈페이지에 오신 것을 환영합니다.\r\n\r\nTmaxBizAI는 SuperApp 솔루션을 통해\r\n기업의 미래를 함께 열어가는 IT 리더입니다."}
              </div>
            </div>
          </div>
          <div css={LogoContainer}>
            <div
              className="card-container"
              css={css`
                background: #182f69;
              `}
            >
              <span className="card-title">Expertise</span>
              <span className="card-content">
                기업의 진보와 혁신을 이뤄내기 위해 필요한
                <br />
                비즈니스 소프트웨어를 개발하는 IT 전문 기업
              </span>
              <div className="icon-wrapper">
                <ExpertiseIcon />
              </div>
            </div>
            <div
              className="card-container"
              css={css`
                background: #4e3280;
              `}
            >
              <span className="card-title">Innovation</span>
              <span className="card-content">
                {
                  "다양한 산업 분야의 지속적인 Data Transformation에\r\n대한 니즈 증대, 고객 DT성공에 필요한 모든 서비스 제공"
                }
              </span>
              <div className="icon-wrapper">
                <InnovationIcon />
              </div>
            </div>
            <div
              className="card-container"
              css={css`
                background: #591464;
              `}
            >
              <span className="card-title">Responsibility</span>
              <span className="card-content">
                기업이 꿈꾸는 더 밝은 미래를 위한
                <br />
                지속적인 투자와 연구
              </span>
              <div className="icon-wrapper">
                <ResponsibilityIcon />
              </div>
            </div>
          </div>
          <div className="card-text">
            {window.outerWidth > 375
              ? "공식홈페이지를 통해 저희의 비전과 미션을 알아보시고,\r\n최신 AI 기술을 적용한 다양한 비지니스 솔루션을 확인해 보세요."
              : "공식홈페이지를 통해 저희의 비전과\r\n미션을 알아보시고, 최신 AI 기술을 적용한\r\n다양한 비지니스 솔루션을 확인해 보세요."}
          </div>
        </div>
        <div css={BottomBanner}>
          <div className="content-area">
            <div className="icon-wrapper">
              <ThumbUpIcon />
            </div>
            <div className="content-text">
              {
                "고객 여러분의 성공이 곧 저희의 성공입니다.\r\n앞으로도 많은 관심과 성원 부탁드립니다."
              }
            </div>
          </div>
        </div>

        <div
          css={BackBtn}
          onClick={() => {
            history("/announcement");
          }}
        >
          목록으로 돌아가기
        </div>
      </div>
    </>
  );
};

export default AnnouncementDetail;

const ContentContainer = css`
  margin: 40px 360px;
  display: flex;
  flex-flow: column;

  @media only screen and (max-width: 375px) {
    margin: 0px 20px;
  }

  .content-title-area {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #e5e5e5;

    @media only screen and (max-width: 375px) {
      padding: 20px 0px;
      height: 96px;
      flex-flow: column;
      gap: 8px;
      align-items: start;
    }

    .content-title {
      font-family: Pretendard;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      color: #1e1e1e;
    }
    .content-date {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 400;
      line-height: 25.6px;
      letter-spacing: -0.004em;
      text-align: center;
      color: #4b5563;
    }
  }

  .content-area {
    display: flex;
    padding: 40px 24px;
    flex-flow: column;

    @media only screen and (max-width: 375px) {
      padding: 30px 0px;
    }

    .content-summary {
      text-align: left;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      color: #000000;
    }

    .card-text {
      font-family: Pretendard;
      font-size: 18px;
      font-weight: 500;
      line-height: 25.2px;
      letter-spacing: -0.01em;
      text-align: center;
      color: #1e1e1e;
      margin-top: 30px;

      @media only screen and (max-width: 375px) {
        font-size: 13px;
        line-height: 18.2px;
        letter-spacing: -0.01em;
        text-align: center;
      }
    }
  }
`;

const BannerStyle = css`
  display: flex;
  background: linear-gradient(
    259.72deg,
    rgba(204, 219, 255, 0.8) -0.89%,
    rgba(205, 216, 254, 0.8) 9.36%,
    rgba(215, 220, 254, 0.8) 19.61%,
    rgba(205, 213, 254, 0.8) 40.11%,
    rgba(205, 221, 254, 0.8) 50.36%,
    rgba(205, 234, 254, 0.8) 70.85%,
    rgba(205, 239, 254, 0.8) 81.1%,
    rgba(205, 226, 254, 0.8) 101.6%
  );
  position: relative;
  height: 661px;
  width: 100%;
  margin: 40px 0px;

  @media only screen and (max-width: 375px) {
    height: 480px;
  }

  .gradient-area {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    width: 100%;
    height: 400px;
    align-self: end;
  }

  .top-circle {
    background: linear-gradient(135deg, #ffffff 0%, rgba(255, 255, 255, 0) 49.56%);
    width: 160px;
    height: 160px;
    top: 80px;
    left: 160px;
    gap: 0px;
    opacity: 0px;
    border-radius: 100%;
    position: absolute;

    @media only screen and (max-width: 375px) {
      width: 82px;
      height: 82px;
      top: 20px;
      left: 20px;
    }
  }

  .right-circle {
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.1) 50.5%,
      rgba(255, 255, 255, 0) 50.51%
    );
    width: 160px;
    height: 160px;
    top: 320px;
    right: 160px;
    gap: 0px;
    opacity: 0px;
    angle: -180 deg;
    border-radius: 100%;
    position: absolute;

    @media only screen and (max-width: 375px) {
      width: 82px;
      height: 82px;
      top: 52px;
      right: 20px;
    }
  }
  .content-area {
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 25%;
    left: 25%;

    @media only screen and (max-width: 375px) {
      top: 47px;
      left: 0px;
      padding: 0px 39px;
    }

    .title-text {
      font-family: Pretendard;
      font-size: 36px;
      font-weight: 500;
      line-height: 43.2px;
      letter-spacing: -0.02em;
      text-align: start;
      color: #222222;
      padding: 0px 56px;

      @media only screen and (max-width: 375px) {
        font-size: 24px;
        padding: 0px;
        line-height: 33.2px;
      }

      &:last-of-type {
        margin-bottom: 22px;
      }
    }

    .sub-text {
      margin-top: 15px;
      font-family: Pretendard;
      font-size: 18px;
      font-weight: 500;
      line-height: 25.2px;
      letter-spacing: -0.01em;
      text-align: center;
      color: #1e1e1e;

      @media only screen and (max-width: 375px) {
        font-size: 13px;
        font-weight: 500;
        line-height: 18.2px;
        text-align: center;
      }
    }
  }
`;

const LogoContainer = css`
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-flow: row;
  gap: 24px;
  flex-wrap: wrap;

  justify-content: center;

  @media only screen and (max-width: 375px) {
    margin-top: 20px;
  }

  .card-container {
    display: inline-flex;
    width: 320px;
    height: 200px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    position: relative;
    overflow: hidden;

    border-radius: 20px;
    flex-shrink: 0;
    box-sizing: border-box;

    @media only screen and (max-width: 375px) {
      padding: 24px;
      width: 335px;
      height: 180px;
    }

    .card-title {
        color: #fff;
        font-family: Pretendard;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 19.6px;
        @media only screen and (max-width: 375px) {
          font-size: 1.25rem;
        }
      }
      .card-content {
        color: #fff;
        font-family: Pretendard;
        font-size: 0.813rem;
        font-style: normal;
        font-weight: 400;
        line-height: 145%;
        text-align: start;
        z-index: 1;
    
        @media only screen and (max-width: 375px) {
          font-size: 0.875rem;
        }
      }
      .icon-wrapper {
        position: absolute;
        bottom: -5px;
        right: 0;
        svg {
            width: 144px;
            height: 148px;
          }
  }
`;

const BottomBanner = css`
  width: 100%;
  height: 310px;
  display: flex;
  border: 1px solid #eeeeee;
  background: linear-gradient(0deg, rgba(3, 100, 255, 0.1), rgba(3, 100, 255, 0.1));
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 375px) {
    height: 241px;
  }

  .content-area {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-wrapper {
      width: 124px;
      height: 124px;
      top: 60px;
      border-radius: 100%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 375px) {
        width: 88px;
        height: 88px;

        svg {
          width: 54px;
          height: 40px;
        }
      }
    }

    .content-text {
      font-family: Pretendard;
      font-size: 18px;
      font-weight: 500;
      line-height: 25.2px;
      letter-spacing: -0.01em;
      text-align: center;
      çolor: #1e1e1e;

      margin-top: 16px;

      @media only screen and (max-width: 375px) {
        font-size: 13px;
        line-height: 18.2px;
        letter-spacing: -0.01em;
      }
    }
  }
`;

const BackBtn = css`
  width: 160px;
  height: 40px;
  border-radius: 4px;
  background: #1e1e1e;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;

  color: #ffffff;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  margin-top: 40px;

  @media only screen and (max-width: 375px) {
    margin: 32px 0px;
  }
`;

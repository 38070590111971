import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Home/home";
import Intro from "./About/intro";
import Porduct from "./Product/product";
import Contact from "./Contact/contact";
import { css } from "@emotion/react";
import Footer from "../components/Footer/footer";
import MediaPage from "./Media/media";
import Companies from "./Companies/companies";
import Announcement from "./Annoncement/announcement";

const Layout = () => {
  const location = useLocation();

  return (
    <>
      <div
        css={css`
          background: #eee;
          min-height: 646px;
          display: flex;
          width: 100%;
          flex-flow: column;

          @media only screen and (max-width: 375px) {
            min-height: unset;
          }
        `}
      >
        <Routes>
          <Route exact path="/" Component={Home} />
          <Route path="/intro/*" Component={Intro} />
          <Route path="/product/*" Component={Porduct} />
          <Route path="/contact" Component={Contact} />
          <Route path="/media/*" Component={MediaPage} />
          <Route path="/companies" Component={Companies} />
          <Route path="/announcement/*" Component={Announcement} />
        </Routes>
        <Footer />
      </div>
    </>
  );
};

export default Layout;

import React from "react";
import { css } from "@emotion/react";
import { Route, Routes, useLocation } from "react-router-dom";
import About from "./about";
import MediaPage from "../Media/media";
import History from "./history";

const Intro = () => {
  const location = useLocation();

  const pathMatch = (pathname) => {
    let returnVal = false;
    location.pathname.split("/")[2] === pathname ? (returnVal = true) : (returnVal = false);
    return returnVal;
  };

  return (
    <>
      <div
        css={css`
          width: inherit;
          display: flex;
          background: white;
          flex-flow: column;
          margin-top: ${pathMatch("media") || pathMatch("history") ? `0px` : `-111px`};

          @media only screen and (max-width: 375px) {
            margin-top: 0px;
          }
        `}
      >
        <Routes>
          <Route path="/about" Component={About} />
          <Route path="/history" Component={History} />
          <Route path="/media/*" Component={MediaPage} />
        </Routes>
      </div>
    </>
  );
};

export default Intro;

import React from "react";
import { css } from "@emotion/react";
import AnnouncementList from "./announcementList";
import AnnouncementDetail from "./announcementDetail";
import { Route, Routes, useLocation } from "react-router-dom";

const Announcement = () => {
  const contentList = [{ title: "[TmaxBizAI] 홈페이지 오픈!", date: "2024.08.16", id: 1 }];
  return (
    <div css={wrapperStyle}>
      <div css={HeaderWrapper}>공지사항</div>
      <Routes>
        <Route exact path="/" Component={() => <AnnouncementList contentList={contentList} />} />
        <Route path="/:id" Component={() => <AnnouncementDetail contentList={contentList} />} />
      </Routes>
    </div>
  );
};

export default Announcement;

const wrapperStyle = css`
  width: inherit;
  background: #fff;
  text-align: center;

  @media only screen and (max-width: 375px) {
    margin-top: 0px;
  }
`;

const HeaderWrapper = css`
  color: #1e1e1e;
  font-family: Pretendard;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  padding: 60px 18.75vw;
  text-align: start;

  @media only screen and (max-width: 375px) {
    padding: 16px 20px;
    font-size: 1.25rem;
  }
`;

import React, { useRef } from "react";
import "./App.css";
import Layout from "./components/layout";
import Header from "./components/Header/header";

import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";

function App() {
  const pointRef = useRef(null);

  return (
    <div className="App">
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Header pointRef={pointRef} />
          <Layout />
        </I18nextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

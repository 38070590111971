import React from "react";
import { css } from "@emotion/react";
import TestImg from "../../resources/Media/test_img.png";
import TmaxBINews from "../../resources/Media/tmax_bi_news.png";
import ThirdImg from "../../resources/Media/media_third.png";
import FourthImg from "../../resources/Media/media_fourth.png";

const NewsPage = () => {
  const [newsList, setNewsList] = React.useState([
    { url: "http://www.worktoday.co.kr/news/articleView.html?idxno=47799" },
    { url: "https://www.ebn.co.kr/news/view/1478038" },
    { url: "https://www.it-b.co.kr/news/articleView.html?idxno=47793" },
    { url: "https://www.it-b.co.kr/news/articleView.html?idxno=46969" },
  ]);

  return (
    <>
      <div css={Wrapper}>
        <div css={NewsContWrapper}>
          <div css={ContentWrapper} onClick={() => (window.location.href = newsList[0].url)}>
            <div className="title-area">
              <div className="title">
                티맥스티베로·티맥스클라우드·티맥스비아이 등 티맥스 계열 3사, ‘성남시
                청년고용우수기업’ 선정
              </div>
              <div className="date">2024.01.22</div>
            </div>
            <div className="img-area">
              <img src={TestImg} />
            </div>
          </div>

          <div css={ContentWrapper} onClick={() => (window.location.href = newsList[1].url)}>
            <div className="title-area">
              <div className="title">티맥스비아이, 스타트업·중소기업 AI 기반 데이터 활용 지원</div>
              <div className="date">2021.04.01</div>
            </div>
            <div className="img-area">
              <img src={TmaxBINews} />
            </div>
          </div>

          <div css={ContentWrapper} onClick={() => (window.location.href = newsList[2].url)}>
            <div className="title-area">
              <div className="title">
                티맥스비아이-유아이네트웍스, 교통분야 빅데이터 비즈니스 협업 나선다
              </div>
              <div className="date">2021.03.04</div>
            </div>
            <div className="img-area">
              <img src={ThirdImg} />
            </div>
          </div>

          <div css={ContentWrapper} onClick={() => (window.location.href = newsList[3].url)}>
            <div className="title-area">
              <div className="title">티맥스비아이-공감아이티, 빅데이터 사업 손잡았다</div>
              <div className="date">2021.01.27</div>
            </div>
            <div className="img-area">
              <img src={FourthImg} />
            </div>
          </div>
          {/* 
          <div className="btn-wrapper">
            <div className="btn">더보기</div>
          </div>
          */}
        </div>
      </div>
    </>
  );
};

export default NewsPage;

const Wrapper = css`
  //border-top: 1px solid #e5e5e5;
  padding: 90px 17vw 150px 18.75vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  @media only screen and (max-width: 375px) {
    padding: 0px 20px;
  }
`;

const NewsContWrapper = css`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: inherit;

  @media only screen and (max-width: 375px) {
    gap: 32px;
  }

  .btn-wrapper {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    padding: 20px;

    .btn {
      border-radius: 28px;
      border: 1px solid #1e1e1e;
      display: flex;
      padding: 16px 70px;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @media only screen and (max-width: 375px) {
        width: 100%;
      }
    }
  }
`;

const ContentWrapper = css`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 588px;
  margin-bottom: 49px;
  cursor: pointer;

  @media only screen and (max-width: 375px) {
    width: inherit;
    flex-direction: row-reverse;
    margin-bottom: 0px;
    padding: 24px 0px;
    border-bottom: 1px solid #aaaaaa;

    &:first-of-type {
      border-top: 1px solid #aaaaaa;
    }
  }

  .title-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .title {
      display: block;
      width: 588px;
      text-align: start;

      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
        width: 235px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: unset;
      }
    }

    .date {
      color: #aaa;

      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: Pretendard;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      @media only screen and (max-width: 375px) {
        font-size: 0.75rem;
      }
    }
  }

  .img-area {
    display: flex;
    padding-bottom: 2px;
    align-items: center;

    @media only screen and (max-width: 375px) {
      padding-bottom: 0px;
      width: 84px;
      height: 72px;
      overflow: hidden;
      border-radius: 12px;
      border: 1px solid var(--Line-Normal, #e5e5e5);
    }

    img {
      border-radius: 20px;
      border: 1px solid #e5e5e5;

      @media only screen and (max-width: 375px) {
        width: 148.537px;
        height: 82px;
      }
    }
  }
`;

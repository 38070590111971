import React, { useState } from "react";
import { css } from "@emotion/react";
import { ReactComponent as ListIcon } from "../../resources/list_icon.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HeaderArrow } from "../../resources/header_arrow.svg";

const headerCont = [
  { path: "/intro/about", name: "회사소개" },
  { path: "/product/focus", name: "상품소개" },
  { path: "/announcement", name: "공지사항" },
  { path: "/contact", name: "도입문의" },
  { path: "/companies", name: "그룹사" },
];

const AboutConst = [
  { path: "/intro/about", name: "TmaxBizAI" },
  { path: "/intro/history", name: "연혁" },
  { path: "/intro/media/news", name: "언론보도" },
];

const ProductConst = [
  { path: "/product/focus", name: "Focus" },
  { path: "/product/solomon", name: "Solomon" },
  { path: "/product/forte", name: "Forte" },
  { path: "/product/project", name: "Forte Project" },
];

function DropDownMenu(props) {
  const [visibility, setVisibility] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [productVisibility, setProductVisibility] = useState(false);
  const [companyVisible, setCompanyVisible] = useState(false);

  const history = useNavigate();

  return (
    <>
      <div
        onClick={(e) => {
          setVisibility(!visibility);
        }}
      >
        <ListIcon />
      </div>
      {visibility && (
        <div css={optionStyle}>
          <ul>
            {headerCont.map((option, index) => (
              <>
                {option.name === "상품소개" ? (
                  <>
                    <li
                      key={index}
                      className={selectedOption === option ? "active-option" : null}
                      onClick={() => {
                        setProductVisibility(!productVisibility);
                      }}
                    >
                      {option.name}
                      <HeaderArrow
                        css={css`
                          transform: ${productVisibility ? `` : `rotate(180deg)`};
                          transition: all ease 0.5s;
                        `}
                      />
                    </li>
                    {productVisibility &&
                      ProductConst.map((option, idx) => (
                        <li
                          key={idx}
                          className={selectedOption === option ? "active-option" : null}
                          onClick={() => {
                            history(option.path);
                            setVisibility(false);
                          }}
                          css={css`
                            margin-left: 10px;
                            color: #1e1e1e !important;
                            font-family: Pretendard;
                            font-size: 1rem !important;
                            font-style: normal !important;
                            font-weight: 400 !important;
                            line-height: 160% !important;
                            letter-spacing: -0.064px !important;
                          `}
                        >
                          {option.name}
                        </li>
                      ))}
                  </>
                ) : option.name === "회사소개" ? (
                  <>
                    <li
                      key={index}
                      className={selectedOption === option ? "active-option" : null}
                      onClick={() => {
                        setAboutVisible(!aboutVisible);
                      }}
                    >
                      {option.name}
                      <HeaderArrow
                        css={css`
                          transform: ${aboutVisible ? `` : `rotate(180deg)`};
                          transition: all ease 0.5s;
                        `}
                      />
                    </li>
                    {aboutVisible &&
                      AboutConst.map((option, idx) => (
                        <li
                          key={idx}
                          className={selectedOption === option ? "active-option" : null}
                          onClick={() => {
                            history(option.path);
                            setVisibility(false);
                          }}
                          css={css`
                            margin-left: 10px;
                            color: #1e1e1e !important;
                            font-family: Pretendard;
                            font-size: 1rem !important;
                            font-style: normal !important;
                            font-weight: 400 !important;
                            line-height: 160% !important;
                            letter-spacing: -0.064px !important;
                          `}
                        >
                          {option.name}
                        </li>
                      ))}
                  </>
                ) : option.name === "그룹사" ? (
                  <>
                    <li
                      key={index}
                      className={selectedOption === option ? "active-option" : null}
                      onClick={() => {
                        setCompanyVisible(!companyVisible);
                      }}
                    >
                      {option.name}
                      <HeaderArrow
                        css={css`
                          transform: ${companyVisible ? `` : `rotate(180deg)`};
                          transition: all ease 0.5s;
                        `}
                      />
                    </li>
                    {companyVisible && (
                      <>
                        <li
                          className={selectedOption === option ? "active-option" : null}
                          onClick={() => {
                            history("/companies");
                            setVisibility(false);
                          }}
                          css={css`
                            margin-left: 10px;
                            color: #1e1e1e !important;
                            font-family: Pretendard;
                            font-size: 1rem !important;
                            font-style: normal !important;
                            font-weight: 400 !important;
                            line-height: 160% !important;
                            letter-spacing: -0.064px !important;
                          `}
                        >
                          {"Tmax Group"}
                        </li>
                        <li
                          className={selectedOption === option ? "active-option" : null}
                          onClick={() => {
                            window.location.href = "https://tmaxcareers.ninehire.site/";
                            setVisibility(false);
                          }}
                          css={css`
                            margin-left: 10px;
                            color: #1e1e1e !important;
                            font-family: Pretendard;
                            font-size: 1rem !important;
                            font-style: normal !important;
                            font-weight: 400 !important;
                            line-height: 160% !important;
                            letter-spacing: -0.064px !important;
                          `}
                        >
                          {"채용문의"}
                        </li>
                      </>
                    )}
                  </>
                ) : (
                  <li
                    key={index}
                    className={selectedOption === option ? "active-option" : null}
                    onClick={() => {
                      history(option.path);
                      setVisibility(false);
                    }}
                  >
                    {option.name}
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default DropDownMenu;

const optionStyle = css`
  position: absolute;
  top: 52px;
  left: 0;
  width: 100%;

  border: 1px solid #ddd;
  background: #fff;
  li {
    height: 52px;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-weight: 600;
    list-style: none;
    line-height: 36px;
    padding: 0 10px;
    cursor: pointer;
    color: #3a404b;
    transition: 0.3s linear;
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      color: #000;
      background: rgba(10, 39, 206, 0.082);
    }
    &.active-option {
      color: #000;
      background: rgba(10, 39, 206, 0.082);
    }
  }
`;

import React from "react";
import { css } from "@emotion/react";
import { Route, Routes } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import NewsPage from "./news";
import SocialPage from "./socail";

const MediaPage = () => {
  const history = useNavigate();
  const location = useLocation();

  const pathMatch = (pathname) => {
    let returnVal = false;
    location.pathname.split("/")[2] === pathname ? (returnVal = true) : (returnVal = false);
    return returnVal;
  };

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div
      css={css`
        background: #fff;
      `}
    >
      <div css={HeaderWrapper}>언론보도</div>
      {/* 
      <div css={TabWrapper}>
        <div
          css={TabChip(pathMatch("news"))}
          onClick={() => {
            history("news");
          }}
        >
          보도 자료
        </div>
       * <div className="tab-chip">관련 뉴스</div>
        <div
          css={TabChip(pathMatch("social"))}
          onClick={() => {
            history("social");
          }}
        >
          소셜미디어
        </div>
      </div>
*/}
      <div>
        <Routes>
          <Route path="/news" Component={NewsPage} />
          <Route path="/social" Component={SocialPage} />
        </Routes>
      </div>
    </div>
  );
};

export default MediaPage;

const HeaderWrapper = css`
  color: #1e1e1e;
  font-family: Pretendard;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  padding: 60px 18.75vw;
  text-align: start;

  @media only screen and (max-width: 375px) {
    padding: 16px 20px;
    font-size: 1.25rem;
  }
`;

const TabWrapper = css`
  display: flex;
  padding: 17px 18.75vw;
  gap: 16px;

  @media only screen and (max-width: 375px) {
    padding: 10px 20px;
    gap: 0;
  }
`;

const TabChip = (pathMatch) => css`
  width: 120px;
  height: 38px;
  cursor: pointer;
  color: ${pathMatch ? `#FFF` : `#aaa`};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  border-radius: ${pathMatch ? `20px` : ``};
  background: ${pathMatch ? `#117CE9` : ``};

  transition: all ease 0.2s;

  @media only screen and (max-width: 375px) {
    font-size: 0.875rem;
    width: 100px;
    height: 40px;
  }
`;

import React from "react";
import { css } from "@emotion/react";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import useKakaoLoader from "../../hook/useKakaoLoader";

const Contact = () => {
  useKakaoLoader();

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div
        css={css`
          width: inherit;
        `}
      >
        <div css={ContactWrapper}>
          <div css={ContactIfoStyle}>
            <div className="title_wrapper">
              <span className="contact_title">도입 문의</span>
            </div>
            <div className="row">
              <span className="column">대표전화</span>
              <span className="value">031-776-9186</span>
            </div>
            <div className="row">
              <span className="column">이메일</span>
              <span className="value" style={{ marginLeft: 100 }}>
                tmaxbizai_info@tmax.co.kr
              </span>
            </div>
            <div className="row">
              <span className="column">홈페이지</span>
              <span className="value">https://www.tmaxbizai.com</span>
            </div>
          </div>
        </div>
        <div css={MapWrapper}>
          <span css={TitleSpan}>찾아오시는 길</span>
          <div css={MapContentWrapper}>
            <div className="map_container">
              <Map
                id="map"
                center={{
                  lat: 37.379208627925,
                  lng: 127.11648944818,
                }}
                style={
                  window.outerWidth > 375
                    ? {
                        width: "30.588px",
                        height: "361.534px",
                      }
                    : {
                        width: "100%",
                        height: "361.534px",
                      }
                }
                level={3}
              >
                <MapMarker
                  position={{
                    lat: 37.379208627925,
                    lng: 127.11648944818,
                  }}
                />
              </Map>
              <div className="row">
                <span className="column">본사</span>
                <span
                  className="value"
                  style={window.outerWidth > 375 ? { marginLeft: 115 } : { marginLeft: 12 }}
                >
                  경기도 성남시 분당구 황새울로 258번길 31
                </span>
              </div>
            </div>
            <div className="map_container">
              <Map
                id="map"
                center={{
                  lat: 37.337699,
                  lng: 127.109737,
                }}
                style={
                  window.outerWidth > 375
                    ? {
                        width: "588px",
                        height: "361.534px",
                      }
                    : {
                        width: "100%",
                        height: "361.534px",
                      }
                }
                level={3}
              >
                <MapMarker
                  position={{
                    lat: 37.337699,
                    lng: 127.109737,
                  }}
                />
                <div className="row">
                  <span className="column">{"R&D 센터"}</span>
                  <span
                    className="value"
                    style={window.outerWidth > 375 ? { marginLeft: 76 } : { marginLeft: 12 }}
                  >
                    경기도 성남시 분당구 구미로 8
                  </span>
                </div>
              </Map>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

const ContactWrapper = css`
  display: flex;
  height: 562px;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
    height: 320px;
    box-sizing: border-box;
  }
`;

const TitleSpan = css`
  color: #1e1e1e;
  font-family: Pretendard;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 127.778%;

  @media only screen and (max-width: 375px) {
    font-size: 1.25rem;
  }
`;

const ContactIfoStyle = css`
  width: 1200px;
  text-align: start;

  .title_wrapper {
    padding-bottom: 60px;
    border-bottom: 2px solid #1e1e1e;

    @media only screen and (max-width: 375px) {
      padding-bottom: 32px;
    }
  }

  .contact_title {
    color: #1e1e1e;
    font-family: Pretendard;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 127.778%;

    @media only screen and (max-width: 375px) {
      font-size: 1.25rem;
    }
  }

  .row {
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    height: 72px;

    @media only screen and (max-width: 375px) {
      height: 53px;
    }
  }

  .column {
    color: #aaa;

    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 133.333%;

    @media only screen and (max-width: 375px) {
      font-size: 0.875rem;
    }
  }

  .value {
    color: #1e1e1e;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 133.333%;
    margin-left: 84px;

    @media only screen and (max-width: 375px) {
      font-size: 0.875rem;
    }
  }
`;

const MapWrapper = css`
  background: #fff;
  text-align: start;
  display: flex;
  flex-flow: column;

  padding: 120px 360px;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
  }
`;

const MapContentWrapper = css`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;

  @media only screen and (max-width: 375px) {
    margin-top: 32px;
    flex-flow: column;
  }

  .map_container {
    display: inline-flex;
    flex-flow: column;
  }

  .row {
    margin-top: 32px;
    border-top: 2px solid #1e1e1e;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    height: 72px;

    @media only screen and (max-width: 375px) {
      height: 53px;
    }
  }

  .column {
    color: #aaa;

    font-family: Pretendard;
    font-size: 1.125;
    font-style: normal;
    font-weight: 700;
    line-height: 133.333%;

    @media only screen and (max-width: 375px) {
      font-size: 0.875rem;
      width: 60px;
    }
  }

  .value {
    color: #1e1e1e;
    font-family: Pretendard;
    font-size: 1.125;
    font-style: normal;
    font-weight: 700;
    line-height: 133.333%;

    @media only screen and (max-width: 375px) {
      font-size: 0.875rem;
    }
  }
`;

import React from "react";
import { css } from "@emotion/react";
import { ReactComponent as SolomonImg } from "../../resources/Product/solomon/dataFrame_solomon_img.svg";
import { ReactComponent as DataCubeImg } from "../../resources/Product/solomon/dataFrame_dataCube_img.svg";
import { ReactComponent as MetaImg } from "../../resources/Product/solomon/dataFrame_Meta_img.svg";
import { ReactComponent as MobileImg } from "../../resources/Product/solomon/dataFrame_mobile_img.svg";

import { AxStudioVector, DataCubeVector, MetaVector, SolomonVector } from "./vector";

const DataFrame = () => {
  const [imgSelect, setImgSelect] = React.useState(0);
  const [delay, setDelay] = React.useState(false);

  const changeNubmer = (imgVal) => {
    let val = imgVal + 1;
    if (val > 2) {
      setImgSelect(0);
    } else {
      setImgSelect(val);
    }
  };

  function useInterval(callback, delay) {
    const savedCallback = React.useRef();
    React.useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(
    () => {
      changeNubmer(imgSelect);
    },
    !delay ? 2000 : null
  );

  return (
    <>
      <div css={DataFrameWrapper}>
        <div className="dataFrame-diagram-area">
          {window.outerWidth > 375 ? (
            <div className="diagram_wrapper">
              <AxStudioVector />
              <DataCubeVector />
              <MetaVector />
              <SolomonVector />
              <div
                className="product-diagram"
                css={css`
                  top: 0;
                  left: 0;
                `}
              >
                AX Studio
              </div>
              <div
                className="product-diagram"
                css={css`
                  top: 0;
                  right: 0;
                  cursor: pointer;
                  &:hover {
                    background: #bed4f7 !important;
                  }
                  transition: all ease 0.5s;
                `}
                onMouseOver={() => {
                  setDelay(true);
                  setImgSelect(0);
                }}
                onMouseLeave={() => {
                  setDelay(false);
                }}
              >
                SOLOMON
                <br />
                Studio
              </div>
              <div
                className="product-diagram"
                css={css`
                  top: 107px;
                  right: 0;
                  background: #ffe0e8 !important;
                  color: #ff0f65 !important;
                  cursor: pointer;

                  &:hover {
                    background: #ffcdd9 !important;
                  }
                  transition: all ease 0.5s;
                `}
                onMouseOver={() => {
                  setDelay(true);
                  setImgSelect(1);
                }}
                onMouseLeave={() => {
                  setDelay(false);
                }}
              >
                Data Cube
                <br /> Data Mart
              </div>

              <div
                className="solution-diagram"
                css={css`
                  bottom: 117px;
                  cursor: pointer;

                  &:hover {
                    background: #184db2 !important;
                  }
                  transition: all ease 0.5s;
                `}
                onMouseOver={() => {
                  setImgSelect(2);
                  setDelay(true);
                }}
                onMouseLeave={() => {
                  setDelay(false);
                }}
              >
                <span className="solution">{"데이터 가상화 & 자동 통합"}</span>
                <span className="sub-solution">{"(Meta Data 구축 기반)"}</span>
              </div>

              <div
                className="solution-diagram"
                css={css`
                  bottom: 0;
                  background: #325290 !important;
                `}
              >
                <span className="solution">{"Data Source"}</span>
                <span className="sub-solution">{"(Tibero, Oracle, Others)"}</span>
              </div>
            </div>
          ) : (
            <MobileImg />
          )}
        </div>
        {window.outerWidth > 375 ? (
          <div className="diagram-detail-area">
            <div className="diagram-img-wrapper">
              {
                {
                  0: <SolomonImg />,
                  1: <DataCubeImg />,
                  2: <MetaImg />,
                }[imgSelect]
              }
            </div>
            <div className="diagram-text-area">
              <span className="product-name">
                {
                  {
                    0: "Solomon\r\nStudio",
                    1: "Data Cube\r\nData Mart",
                    2: "데이터 가상화 &\r\n자동 통합",
                  }[imgSelect]
                }
              </span>
              <span className="product-sub-name">
                {
                  {
                    0: "AI 모델 개발 적합한 통합 데이터 제공 및 데이터 시각화를 통한 \r\n의사결정 지원",
                    1: "ETL 의존없이, 즉시 데이터 분석 수행",
                    2: "데이터 가상화 & 자동통합 \r\n(Meta Data 구축을 기반으로)",
                  }[imgSelect]
                }
              </span>
            </div>
          </div>
        ) : (
          <div className="mobile-img-wrapper">
            <div className="mobile-img">
              {
                {
                  0: <SolomonImg />,
                  1: <DataCubeImg />,
                  2: <MetaImg />,
                }[imgSelect]
              }
            </div>
            <div className="mobile-text-area">
              <span className="product-name">
                {
                  {
                    0: "Solomon\r\nStudio",
                    1: "Data Cube\r\nData Mart",
                    2: "데이터 가상화 &\r\n자동 통합",
                  }[imgSelect]
                }
              </span>
              <span className="product-sub-name">
                {
                  {
                    0: "AI 모델 개발 적합한 통합 데이터 제공 및 데이터 시각화를 통한 의사결정 지원",
                    1: "ETL 의존없이, 즉시 데이터 분석 수행\r\n",
                    2: "데이터 가상화 & 자동통합 \r\n(Meta Data 구축을 기반으로)",
                  }[imgSelect]
                }
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DataFrame;

const DataFrameWrapper = css`
  display: flex;
  padding: 32px 48px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  flex-flow: row;

  @media only screen and (max-width: 375px) {
    flex-flow: column;
    padding: 16px 20px;
  }

  border-radius: 20px;
  background: #f1f4f8;

  .dataFrame-diagram-area {
    display: flex;
    padding: 1px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    @media only screen and (max-width: 375px) {
      width: 100%;
    }

    .diagram_wrapper {
      width: 424px;
      height: 460px;
      position: relative;

      .product-diagram {
        display: flex;
        width: 200px;
        height: 80px;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        position: absolute;

        @media only screen and (max-width: 375px) {
          width: 138.251px;
          height: 60px;
          font-size: 1rem;
        }

        border-radius: 16px;
        background: #ccdef9;

        color: #1c64f2;
        font-family: Pretendard;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }

      .solution-diagram {
        display: flex;
        width: 100%;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        position: absolute;

        border-radius: 16px;
        background: #3263c2;

        @media only screen and (max-width: 375px) {
          padding: 8px 16px;
        }

        .solution {
          color: #fff;
          text-align: center;
          font-family: Pretendard;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
        }
        .sub-solution {
          color: #fff;

          text-align: center;
          font-family: Pretendard;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
  }

  .diagram-detail-area {
    position: relative;
    display: flex;
    height: 420px;
    padding: 24px 48px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    border-left: 2px solid #fff;

    .diagram-img-wrapper {
      position: absolute;
      top: 0;
      right: 0;

      svg {
        animation: fadeIn 2s;
      }
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      transition: all ease 0.5s;
    }

    .diagram-text-area {
      display: flex;
      flex-flow: column;
      position: absolute;
      bottom: 24px;
      left: 48px;
      gap: 24px;

      .product-name {
        color: #1c64f2;
        font-family: Pretendard;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        white-space: pre-line;

        text-align: start;
      }

      .product-sub-name {
        color: #aaa;
        font-family: Pretendard;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-align: start;
        white-space: pre-line;
      }
    }
  }

  .mobile-img-wrapper {
    display: flex;
    width: 100%;
    height: 260px;
    justify-content: center;
    align-items: center;
    position: relative;
    border-top: 2px solid #fff;

    .mobile-img {
      position: absolute;
      top: 0;
      right: 0;
      padding-top: 24px;

      svg {
        width: 228.517px;
        height: 139.129px;
        animation: fadeIn 2s;
      }
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      transition: all ease 0.5s;
    }

    .mobile-text-area {
      display: flex;
      flex-flow: column;
      position: absolute;
      bottom: 0px;
      left: 20px;
      gap: 16px;

      .product-name {
        color: #1c64f2;
        font-family: Pretendard;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;

        text-align: start;
      }

      .product-sub-name {
        color: #aaa;
        font-family: Pretendard;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        text-align: start;
      }
    }
  }
`;

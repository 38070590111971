import React from "react";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const AnnouncementList = ({ contentList }) => {
  const history = useNavigate();
  return (
    <>
      <div css={TableContainer}>
        {window.outerWidth > 375 ? (
          <div className="table-row">
            <div className="content-title">제목</div>
            <div className="content-date">등록일</div>
          </div>
        ) : (
          <></>
        )}

        {contentList.map((item, key) => (
          <div
            className="table-row"
            key={key}
            onClick={() => {
              console.log(item.id);
              history(`${item.id}`);
            }}
          >
            <div className="content-title">{item.title}</div>
            <div className="content-date">{item.date}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AnnouncementList;

const TableContainer = css`
  margin: 40px 360px;
  border-top: 1px solid #1e1e1e;

  @media only screen and (max-width: 375px) {
    margin: 0px 20px;
    min-height: 330px;
  }

  .table-row {
    height: 58px;
    display: flex;
    flex-flow: row;
    border-bottom: 1px solid #e5e5e5;
    padding: 16px 24px;
    cursor: pointer;

    @media only screen and (max-width: 375px) {
      padding: 20px 0px;
      height: 94px;
      flex-flow: column;
      gap: 8px;
    }

    &:first-child {
      cursor: unset;
      .content-title {
        text-align: center;

        @media only screen and (max-width: 375px) {
          cursor: pointer;
          text-align: start;
        }
      }
      .content-date {
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 600;
        line-height: 25.6px;
        letter-spacing: -0.01em;
        color: #1e1e1e;
      }
    }

    .content-title {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 600;
      line-height: 25.6px;
      letter-spacing: -0.01em;
      color: #1e1e1e;
      width: 1096px;
      text-align: left;
    }

    .content-date {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 400;
      line-height: 25.6px;
      letter-spacing: -0.004em;
      color: #4b5563;
      @media only screen and (max-width: 375px) {
        font-size: 14px;
        text-align: left;
        color: #aaaaaa;
      }
    }
  }
`;

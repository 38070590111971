import React from "react";
import { css } from "@emotion/react";
import Banner from "../../resources/Product/focus/focus_banner.png";
import { ReactComponent as FocusLogo } from "../../resources/Product/focus/bi_focus_logo.svg";
import FocusVideo from "../../resources/Product/focus/focus_video.mp4";

import { ReactComponent as D2CIcon } from "../../resources/Product/focus/d2c_icon.svg";
import { ReactComponent as DynamicIcon } from "../../resources/Product/focus/dynamic_marketing.svg";
import { ReactComponent as GenerativeIcon } from "../../resources/Product/focus/generative_icon.svg";

import { ReactComponent as D2CMoblieIcon } from "../../resources/Product/focus/d2c_icon_mobile.svg";
import { ReactComponent as DynamicMobileIcon } from "../../resources/Product/focus/dynamic_marketing_mobile.svg";
import { ReactComponent as GenerativeMobileIcon } from "../../resources/Product/focus/generative_icon_mobile.svg";

import { ReactComponent as CrmSalesIcon } from "../../resources/Product/focus/crm_sales_img.svg";
import { ReactComponent as MetaImg } from "../../resources/Product/focus/meta_img.svg";
import { ReactComponent as AiAnalyticsImg } from "../../resources/Product/focus/ai_data_analytics_img.svg";
import { ReactComponent as AiAnalyticsMobileImg } from "../../resources/Product/focus/ai_data_mobile_img.svg";
import DetailBannerImg from "../../resources/Product/focus/focus_detail_img.png";
import { useNavigate } from "react-router-dom";

const FocusPage = () => {
  const history = useNavigate();

  const videoRef = React.useRef();
  const setPlayBackRate = () => {
    videoRef.current.playbackRate = 0.5;
  };
  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div css={wrapperStyle}>
        <div css={imgWrapper}>
          <img src={Banner} />
        </div>
        <div css={textWrapper}>
          <FocusLogo />
          <div className="head-wrapper">
            {"고객과 나를 연결하는"}
            <br />
            {"CRM & Sales 통합 플랫폼"}
          </div>
          <div className="child-wrapper">
            {window.outerWidth > 378 ? (
              <>
                FOCUS는 AI와 Metaverse 기반의 3세대 CRM++ 입니다.
                <br />
                온라인 세일즈의 모든 비즈니스 사이클을 효율적으로 관리하고
                <br />
                개선된 고객 관리 경험을 제공하여 매출 성장에 기여합니다.
              </>
            ) : (
              <>
                FOCUS는 AI와 Metaverse 기반의 3세대 CRM++
                <br />
                입니다. 온라인 세일즈의 모든 비즈니스 사이클을
                <br />
                효율적으로 관리하고 개선된 고객 관리 경험을 제공
                <br />
                하여 매출 성장에 기여합니다.
              </>
            )}
          </div>
        </div>
      </div>
      <div css={VideoWrapper}>
        <video
          width={window.outerWidth > 375 ? "1200px" : "335px"}
          height={window.outerWidth > 375 ? "460px" : "240px"}
          autoPlay
          loop
          ref={videoRef}
          onCanPlay={() => setPlayBackRate()}
        >
          <source src={FocusVideo} type="video/mp4" />
        </video>
      </div>
      <div css={FocusDescription}>
        <div className="title-wrapper">
          성공적인 D2C 전환이 가능한 이유.
          <br />
          정답은 바로 FOCUS
        </div>
        {window.outerWidth > 375 ? (
          <div className="icon-wrapper">
            <div css={DescriptionCard}>
              <div className="title">
                D2C Sales
                <br />
                Platform
              </div>
              <div className="img-container">
                <D2CIcon />
              </div>
              <div className="description-area">
                <div className="desciprtion-title">고객과 직접 연결되는 비즈니스의 성공</div>
                <span className="description-sub">
                  {
                    "FOCUS는 비즈니스의 성공을 원하는 다양한 분야의\r\n전문가들을 위한 최적의 솔루션을 지원합니다."
                  }
                </span>
              </div>
            </div>

            <div css={DescriptionCard}>
              <div className="title">
                Dynamic
                <br />
                Marketing Contents
              </div>
              <div
                className="img-container"
                css={css`
                  padding: 23px 88.819px 22.939px 88px !important;
                  justify-content: center;
                  align-items: center;

                  border-radius: 20px;
                  background: #282943 !important;

                  width: 384px;
                  height: 240px;
                  box-sizing: border-box;
                  overflow: hidden;

                  @media only screen and (max-width: 375px) {
                    padding: 0px !important;
                    height: 78px;
                    width: 78px;
                  }
                `}
              >
                <DynamicIcon />
              </div>
              <div className="description-area">
                <div className="desciprtion-title">기획자, 마케터가 직접 만드는 3D 콘텐츠</div>
                <span className="description-sub">
                  {window.outerWidth > 378
                    ? "누구나 쉽게 3D/Metaverse 상품 콘텐츠를 제작할 수 있는\r\n스튜디오를 통해, 더욱 생생한 상품 경험을 고객에게\r\n제공합니다."
                    : "누구나 쉽게 3D/Metaverse 상품 콘텐츠를 제작할 수 있는 스튜디오를 통해, 더욱 생생한 상품 경험을 고객에게 제공합니다."}
                </span>
              </div>
            </div>

            <div css={DescriptionCard}>
              <div className="title">
                Generative
                <br />
                Super AI
              </div>
              <div
                css={css`
                padding: 9px 72.82px 0px 0px; !important;
                justify-content: center;
                align-items: center;

                border-radius: 20px;
                background: #117CE9;로

                width: 384px;
                height: 240px;
                box-sizing: border-box;
                overflow: hidden;

                @media only screen and (max-width: 375px) {
                  padding: 0px !important;
                  height: 78px;
                  width: 78px;
                }
              `}
              >
                <GenerativeIcon />
              </div>
              <div className="description-area">
                <div className="desciprtion-title">대화로 실행하는 데이터 분석</div>
                <span className="description-sub">
                  {window.outerWidth > 378
                    ? "AI 신기술과 데이터 분석을 통해 경쟁력을 키울 수 있는\r\n방향 으로 해결 방안을 제시하여 비즈니스의 경쟁력을\r\n강화합니다."
                    : "AI 신기술과 데이터 분석을 통해 경쟁력을 키울 수 있는 방향 으로 해결 방안을 제시하여 비즈니스의 경쟁력을 강화합니다."}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="mobile-icon-wrapper">
            <div className="mobile-card-wrapper">
              <div className="mobile-card-upper">
                <D2CMoblieIcon />
                <div className="mobile-text-area">
                  <div className="mobile-title">{"D2C Sales Platform"}</div>
                  <div className="mobile-sub">{"고객과 직접 연결되는\r\n비즈니스의 성공"}</div>
                </div>
              </div>
              <div className="mobile-card-content">
                {
                  "FOCUS는 비즈니스의 성공을 원하는 다양한 분야의 전문가들\r\n을 위한 최적의 솔루션을 제공합니다."
                }
              </div>
            </div>

            <div className="mobile-card-wrapper">
              <div className="mobile-card-upper">
                <DynamicMobileIcon />
                <div className="mobile-text-area">
                  <div className="mobile-title">{"Dynamic Marketing Contents"}</div>
                  <div className="mobile-sub">{"기획자, 마케터가 직접\r\n만드는 3D 콘텐츠"}</div>
                </div>
              </div>
              <div className="mobile-card-content">
                {
                  "누구나 쉽게 3D/Metaverse 상품 콘텐츠를 제작할 수 있는\r\n스튜디오를 통해, 더욱 생생한 상품 경험을 고객에게\r\n제공합니다."
                }
              </div>
            </div>

            <div className="mobile-card-wrapper">
              <div className="mobile-card-upper">
                <GenerativeMobileIcon />
                <div className="mobile-text-area">
                  <div className="mobile-title">{"Generative Super AI"}</div>
                  <div className="mobile-sub">{"대화로 실행하는\r\n데이터 분석"}</div>
                </div>
              </div>
              <div className="mobile-card-content">
                {
                  "AI 신기술과 데이터 분석을 통해 경쟁력을  키울 수 있는 방향\r\n으로 해결 방안을 제시하여 비즈니스의 경쟁력을 강화합니다."
                }
              </div>
            </div>
          </div>
        )}
      </div>
      <div css={SolutionWrapper}>
        <div className="title">
          고객 관리와 세일즈를 어렵게 했던
          <br />
          고민들과 작별하세요
        </div>
        <div className="card_wrapper">
          <div css={SolutionCardWrapper("#D7F0E6", "#029359")}>
            <span className="badge">경제성</span>
            <div className="text-field">
              광고 수수료, 판매 수수료 없이 <br />
              <span className="bold">고객에게 직접 판매</span>하고 싶어요.
            </div>
          </div>
          <div css={SolutionCardWrapper("#F2E7F9", "#9254DE")}>
            <span className="badge">혁신적</span>
            <div className="text-field">
              밋밋한 2D 상품 상세 페이지 대신,
              <br />
              <span className="bold">더욱 생생한 상품 콘텐츠</span>가 필요해요.
            </div>
          </div>
          <div css={SolutionCardWrapper("#D9EBFD", "#117CE9")}>
            <span className="badge">분석적</span>
            <div className="text-field">
              SNS, 커뮤니티 등 분산된 고객 데이터를
              <br />
              <span className="bold">한 곳으로 모아서 쉽게 분석</span>하고 싶어요.
            </div>
          </div>
        </div>
      </div>
      <div css={ExplantaionWrapper}>
        <div className="title">
          {"완전히 통합된"}
          <br />
          {"CRM & Sales 플랫폼"}
        </div>
        <div className="content-area">
          <div className="img-wrapper">
            <CrmSalesIcon />
          </div>
          <div className="sub-description">
            클라우드 네이티브 환경을 통해 필요한 기능이 통합되어 고객 관리와 세일즈 비즈니스를
            유기적으로 관리할 수 있습니다.
          </div>
        </div>
        <div className="card-container">
          <div className="card-wrapper">
            <span className="card-title">마케팅 자동화</span>
            <span className="card-content">
              저장된 고객 정보 기반으로 AI가 마케팅에 필요한 <br />
              프로모션 기획부터 콘텐츠 발송까지 실행합니다.
            </span>
          </div>

          <div className="card-wrapper">
            <span className="card-title">AI 고객 응대</span>
            <span className="card-content">
              DB 기반으로 구성된 AI 모델을 통해,
              <br />
              고객의 문의에 대해 정확한 정보를 제공합니다.
            </span>
          </div>
          <div className="card-wrapper">
            <span className="card-title">DB를 통한 상품 관리</span>
            <span className="card-content">
              제품, 상품을 DB화하고 판매 등록 또는 콘텐츠 <br />
              제작 시 활용하여 생산 효율성을 향상시킵니다.
            </span>
          </div>
        </div>
      </div>

      <div css={MetaverseWrppaer}>
        <div className="title">
          누구나 쉽게 제작하는
          <br />
          3D, Metaverse 상품 콘텐츠
        </div>
        <div className="content-area">
          <div className="img-wrapper">
            <MetaImg
              css={css`
                width: 780px;
                height: 445px;

                @media only screen and (max-width: 375px) {
                  width: 467px;
                  height: 158px;
                }
              `}
            />
          </div>
          {window.outerWidth > 375 && (
            <div className="text-container">
              <div className="text-wrapper">
                FOCUS 스튜디오를 통해 제공되는 3D, Metaverse 컴포넌트를
              </div>
              <div className="text-wrapper">
                통해 누구나 쉽게 생성한 상품 콘텐츠를 만들 수 있습니다.
              </div>
            </div>
          )}
        </div>
        {window.outerWidth <= 375 && (
          <div className="mobile-text-container">
            <div className="text-wrapper">FOCUS 스튜디오를 통해 제공되는</div>
            <div className="text-wrapper">3D, Metaverse 컴포넌트를 통해 누구나 쉽</div>
            <div className="text-wrapper">게 생성한 상품 콘텐츠를 만들 수 있습니다.</div>
          </div>
        )}
        <div className="card-container">
          <div className="card-wrapper">
            <span className="title">스튜디오로 쉽게 제작</span>
            <span className="content">
              {
                "배경, 모델, 색상 등 다양한 컴포넌트를 활용해 누구나 간단히\r\n3D 콘텐츠를 만들 수 있습니다."
              }
            </span>
          </div>
          <div className="card-wrapper">
            <span className="title">메타버스로 고객 경험을 극대화</span>
            <span className="content">
              {
                "취향, 구매 패턴을 반영한 메타버스 환경을 구현해\r\n맞춤 큐레이션을 제공하여 고객 경험을 차별화합니다."
              }
            </span>
          </div>
        </div>
      </div>

      <div css={AiDataAnalyticsWrapper}>
        <span className="ai-data-title">
          모든 정보를 활용한 인사이트
          <br />
          AI 데이터 분석
        </span>
        <div className="ai-img-wrapper">
          {window.outerWidth > 375 ? <AiAnalyticsImg /> : <AiAnalyticsMobileImg />}

          <div className="ai-img-text-wrapper">
            {window.outerWidth > 375 ? (
              <>
                <span className="ai-img-text">
                  데이터 가상화 기반의 통합 데이터베이스와 AI를 통해
                </span>
                <span className="ai-img-text">
                  실무자가 현업에서 필요한 데이터 분석을 실행할 수 있습니다.
                </span>
              </>
            ) : (
              <>
                <span className="ai-img-text">데이터 가상화 기반의 통합 데이터베이스와 AI</span>
                <span className="ai-img-text">를 통해 실무자가 현업에서 필요한 데이터 분석</span>
                <span className="ai-img-text">을 실행할 수 있습니다.</span>
              </>
            )}
          </div>
        </div>

        <div className="card-container">
          <div className="card-wrapper">
            <span className="card-title">AI 데이터 분석</span>
            <span className="card-content">
              {
                "가상화 기반의 내/외부 데이터 통합 DB를 구성해\r\nAI가 고객 행동이나 마케팅 성과를 분석합니다."
              }
            </span>
          </div>

          <div className="card-wrapper">
            <span className="card-title">사용자 맞춤 대시보드, 보고서</span>
            <span className="card-content">
              {
                "템플릿과 구성 요소들을 컴포넌트로 제공하여 활용 목적에 따라\r\n맞춤 보고서를 생성할 수 있습니다."
              }
            </span>
          </div>
        </div>
      </div>

      <div css={DetailBanner}>
        <img src={DetailBannerImg} />
        <div className="text-area">
          <div
            css={css`
              display: flex;
              gap: 16px;
              flex-flow: column;
            `}
          >
            <span className="title">
              <FocusLogo
                css={css`
                  width: 252px;
                  height: 24px;

                  @media only screen and (max-width: 375px) {
                    width: 126px;
                    height: 12px;
                  }
                `}
              />{" "}
              를 통해 생산성을 혁신하고 매출 향상을 경험하세요
            </span>
            <span className="sub-title">더 자세한 소개 자료와 데모 체험 신청이 가능합니다.</span>
          </div>
          <div
            className="detail-btn"
            onClick={() => {
              history("/contact");
            }}
          >
            FOCUS 시작하기
          </div>
        </div>
      </div>
    </>
  );
};

export default FocusPage;

const wrapperStyle = css`
  width: inherit;
  position: relative;
`;

const imgWrapper = css`
  position: relative;
  width: 100%;
  height: 780px;
  overflow: hidden;

  @media only screen and (max-width: 375px) {
    height: 560px;
  }

  .img-center {
    text-align: center;
    position: absolute;
    top: 0;
    right: -200%;
    bottom: 0;
    left: -200%;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const textWrapper = css`
  position: absolute;
  left: 360px;
  top: 27.82%;

  display: inline-flex;
  flex-flow: column;

  text-align: start;

  @media only screen and (max-width: 375px) {
    left: 20px;
    top: 123px;
  }

  .head-wrapper {
    color: white;
    margin-top: 60px;

    color: #fff;
    font-family: Pretendard;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .child-wrapper {
    color: white;
    margin-top: 32px;

    color: #d7d7d7;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;

    @media only screen and (max-width: 375px) {
      font-size: 1rem;
    }
  }
`;

const VideoWrapper = css`
  display: flex;
  padding: 150px 360px;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  background: #f9fafb;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
  }
`;

const FocusDescription = css`
  display: flex;
  padding: 150px 360px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 60px 24px;
  flex-wrap: wrap;
  background: #fff;
  flex-flow: column;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
  }

  .title-wrapper {
    color: #1e1e1e;
    text-align: start;

    font-family: Pretendard;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;

    @media only screen and (max-width: 375px) {
      flex-flow: column;
    }
  }

  .mobile-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;

    .mobile-card-wrapper {
      display: flex;
      flex-flow: column;
      border-top: 1px solid #e5e5e5;
      padding: 24px 0px;
      gap: 24px;

      &:last-child {
        border-bottom: 1px solid #e5e5e5;
      }

      .mobile-card-upper {
        display: flex;
        flex-flow: row;
        gap: 24px;
        .mobile-text-area {
          display: flex;
          flex-flow: column;
          gap: 8px;
          .mobile-title {
            color: #117ce9;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            text-align: start;
          }
          .mobile-sub {
            color: #1e1e1e;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            text-align: start;
          }
        }
      }

      .mobile-card-content {
        color: #4b5563;
        font-family: Pretendard;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-align: start;
      }
    }
  }
`;

const DescriptionCard = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  .title {
    display: flex;
    height: 56px;
    align-items: center;
    text-align: start;

    color: #117ce9;
    font-family: Pretendard;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 0.75rem;
    }
  }

  .img-container {
    border-radius: 20px;
    background: #117ce9;

    display: flex;
    width: 384px;
    height: 240px;
    padding: 12px 90.139px 11.721px 90px;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;

    @media only screen and (max-width: 375px) {
      width: 78px;
      height: 78px;
      padding: 0px;
    }
  }

  .description-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .desciprtion-title {
      display: flex;
      width: 384px;
      height: 56px;
      justify-content: start;
      align-items: center;

      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }

    .description-sub {
      width: 384px;
      text-align: start;
      color: #797e86;
      font-family: Pretendard;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      letter-spacing: -0.064px;
    }
  }
`;

const SolutionWrapper = css`
  display: flex;
  padding: 150px 360px;
  align-items: flex-start;
  align-content: flex-end;
  gap: 60px 24px;
  flex-wrap: wrap;
  text-align: start;
  flex-flow: column;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
    gap: 32px;
  }

  .title {
    color: #1e1e1e;
    font-family: Pretendard;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .card_wrapper {
    display: flex;
    flex-flow: row;
    gap: 24px;

    @media only screen and (max-width: 375px) {
      flex-flow: column;
    }
  }
`;

const SolutionCardWrapper = (badgeColor, fontColor) => css`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  border-radius: 20px;
  background: #f9fafb;

  @media only screen and (max-width: 375px) {
    padding: 24px;
    gap: 24px;
  }

  .badge {
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    background: ${badgeColor};

    color: ${fontColor};
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 0.875rem;
    }
  }
  .text-field {
    color: #4b5563;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

    width: 320px;

    @media only screen and (max-width: 375px) {
      width: 287px;
      font-size: 0.875rem;
    }

    .bold {
      color: #1e1e1e;

      font-family: Pretendard;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
      }
    }
  }
`;

const ExplantaionWrapper = css`
  display: flex;
  padding: 150px 360px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 60px;
  flex-wrap: wrap;
  flex-flow: column;

  background: #fff;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
    gap: 32px;
  }

  .title {
    color: #1e1e1e;
    font-family: Pretendard;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-align: start;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .content-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    @media only screen and (max-width: 375px) {
      gap: 16px;
    }

    .img-wrapper {
      display: flex;
      padding: 1px 190px 0px 189px;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 375px) {
        padding: 0px;

        svg {
          width: 335px;
          height: 165.261px;
          flex-shrink: 0;
        }
      }
    }
    .sub-description {
      text-align: center;
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      width: 100%;

      background: linear-gradient(98deg, #29a9f6 9%, #117ce9 41.2%, #22c345 74.22%, #0a915e 90.95%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media only screen and (max-width: 375px) {
        font-size: 1.125rem;
        text-align: start;
      }
    }
  }

  .card-container {
    display: flex;
    flex-flow: row;
    gap: 24px;

    @media only screen and (max-width: 375px) {
      flex-flow: column;
    }

    .card-wrapper {
      display: flex;
      padding: 32px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      border-radius: 20px;
      background: #f9fafb;

      @media only screen and (max-width: 375px) {
        padding: 24px;
      }

      .card-title {
        width: 320px;

        color: #1e1e1e;

        font-family: Pretendard;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-align: start;

        @media only screen and (max-width: 375px) {
          width: 287px;
          font-size: 1rem;
        }
      }

      .card-content {
        width: 320px;
        color: #4b5563;
        font-family: Pretendard;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -0.16px;
        text-align: start;

        @media only screen and (max-width: 375px) {
          width: 287px;
          font-size: 0.875rem;
        }
      }
    }
  }
`;

const MetaverseWrppaer = css`
  display: flex;
  padding: 150px 360px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 60px;
  flex-wrap: wrap;
  flex-flow: column;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
    gap: 32px;
  }

  .title {
    color: #1e1e1e;
    font-family: Pretendard;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-align: start;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .content-area {
    border-radius: 20px;
    border: 1px solid rgba(119, 156, 232, 0.2);
    padding-top: 40px;
    padding-right: 40px;

    background: #f9fafb;

    overflow: hidden;
    position: relative;
    width: 1200px;
    box-sizing: border-box;

    @media only screen and (max-width: 375px) {
      width: 100%;
      padding: 0px;
    }

    .img-wrapper {
      overflow: hidden;
      width: 780px;
      height: 406px;
      position: relative;

      @media only screen and (max-width: 375px) {
        width: 100%;
        height: 158px;
        display: flex;
      }
    }
    .text-container {
      display: inline-flex;
      flex-flow: column;
      gap: 12px;
      position: absolute;
      top: 40px;
      right: 40px;

      .text-wrapper {
        display: inline-flex;
        font-family: Pretendard;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        justify-content: end;

        background: linear-gradient(
          98deg,
          #117ce9 9%,
          #117ce9 41.2%,
          #22c345 74.22%,
          #0a915e 90.95%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .mobile-text-container {
    display: inline-flex;
    flex-flow: column;

    .text-wrapper {
      display: inline-flex;
      font-family: Pretendard;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      justify-content: start;

      background: linear-gradient(98deg, #117ce9 9%, #117ce9 41.2%, #22c345 74.22%, #0a915e 90.95%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .card-container {
    display: flex;
    flex-flow: row;
    gap: 24px;

    @media only screen and (max-width: 375px) {
      gap: 16px;
      flex-flow: column;
    }

    .card-wrapper {
      display: flex;
      padding: 32px 32px 28px 32px;
      justify-content: center;
      align-items: start;
      border-radius: 20px;
      background: #f9fafb;
      flex-flow: column;
      gap: 16px;

      @media only screen and (max-width: 375px) {
        padding: 24px;
      }

      .title {
        width: 320px;

        color: #1e1e1e;

        font-family: Pretendard;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-align: start;

        @media only screen and (max-width: 375px) {
          width: 287px;
        }
      }

      .content {
        width: 524px;

        color: #4b5563;
        font-family: Pretendard;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -0.16px;
        text-align: start;

        @media only screen and (max-width: 375px) {
          width: 287px;
          font-size: 0.875rem;
        }
      }
    }
  }
`;

const AiDataAnalyticsWrapper = css`
  display: flex;
  width: 100%;
  padding: 150px 360px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 60px 24px;
  flex-wrap: wrap;
  flex-flow: column;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
    gap: 32px;
  }

  .ai-data-title {
    text-align: start;
    color: var(--Text-Normal, #1e1e1e);
    font-family: Pretendard;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .ai-img-wrapper {
    position: relative;

    @media only screen and (max-width: 375px) {
      gap: 16px;
      display: flex;
      flex-flow: column;
    }
  }
  .ai-img-text-wrapper {
    position: absolute;
    display: flex;
    flex-flow: column;
    top: 40px;
    left: 40px;

    @media only screen and (max-width: 375px) {
      position: relative;
      top: 0;
      left: 0;
    }

    .ai-img-text {
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 1.125rem;
      }

      background: linear-gradient(98deg, #117ce9 9%, #117ce9 41.2%, #22c345 74.22%, #0a915e 90.95%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .card-container {
    display: flex;
    flex-flow: row;
    gap: 24px;
    width: 100%;

    @media only screen and (max-width: 375px) {
      flex-flow: column;
    }

    .card-wrapper {
      display: flex;
      padding: 32px 32px 28px 32px;
      justify-content: center;
      align-items: start;
      border-radius: 20px;
      background: #f9fafb;
      flex-flow: column;
      gap: 16px;
      width: 100%;

      @media only screen and (max-width: 375px) {
        border-radius: 12px;
      }

      .card-title {
        width: 320px;

        @media only screen and (max-width: 375px) {
          width: 100%;
        }

        color: #1e1e1e;

        font-family: Pretendard;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-align: start;
      }

      .card-content {
        width: 524px;

        color: #4b5563;
        font-family: Pretendard;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -0.16px;
        text-align: start;

        @media only screen and (max-width: 375px) {
          font-size: 0.875rem;
          width: 100%;
        }
      }
    }
  }
`;

const DetailBanner = css`
  position: relative;
  @media only screen and (max-width: 375px) {
    height: 240px;
    overflow: hidden;
  }
  .text-area {
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    width: 100%;
    gap: 40px;

    @media only screen and (max-width: 375px) {
      gap: 24px;
      width: 100%;
      padding: 44px;
    }

    .title {
      color: #fff;

      text-align: center;
      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      @media only screen and (max-width: 375px) {
        font-size: 1.125rem;
      }
    }

    .sub-title {
      color: var(--Dark-Text-Normal, #e5e7eb);
      text-align: center;
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      @media only screen and (max-width: 375px) {
        font-size: 0.813rem;
      }
    }

    .detail-btn {
      display: flex;
      width: 300px;
      height: 48px;
      min-width: 131px;
      padding: 10px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      border-radius: 4px;
      background: #fff;

      color: var(--Text-Normal, #1e1e1e);
      text-align: center;
      font-family: Pretendard;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      cursor: pointer;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
        padding: 8px 24px;
        width: 144px;
        font-weight: 600;
        height: 36px;
        width: 147px;
      }
    }
  }
`;

import { css } from "@emotion/react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as BiLogo } from "../../resources/bi_logo.svg";
import { ReactComponent as BiLogoDark } from "../../resources/bi_logo_dark.svg";
import React from "react";
import SelectBox from "../Common/selectBox";
import DropDownMenu from "./dropDownMenu";

const headerCont = [
  { path: "/intro/about", name: "회사소개" },
  { path: "/product/focus", name: "상품소개" },
  { path: "/announcement", name: "공지사항" },
  { path: "/contact", name: "도입문의" },
  { path: "/companies", name: "그룹사" },
];

export default function Header() {
  const history = useNavigate();
  const location = useLocation();

  const [scrollPosition, setScrollPosition] = React.useState(0);

  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", updateScroll);
  });

  const pathMatch = (pathname) => {
    let returnVal = false;
    location.pathname.split("/")[1] === pathname.split("/")[1]
      ? (returnVal = true)
      : (returnVal = false);
    return returnVal;
  };

  const pathMatchProduct = (pathname) => {
    let returnVal = false;
    location.pathname.split("/")[2] === pathname ? (returnVal = true) : (returnVal = false);
    return returnVal;
  };

  const pathNameCheck = (location, state) => {
    const pathName = location.pathname.split("/")[1];
    const pathNameSec = location.pathname.split("/")[2];
    let returnVal = false;

    switch (state) {
      case "equalOR":
        if (
          pathNameSec === "history" ||
          pathNameSec === "media" ||
          pathName === "announcement" ||
          pathName === "contact" ||
          pathName === "companies"
        )
          returnVal = true;
        break;

      case "unequalAND":
        if (
          pathName !== "intro" &&
          pathName !== "contact" &&
          pathName !== "announcement" &&
          pathName !== "companies"
        )
          returnVal = true;
        break;

      default:
        break;
    }
    return returnVal;
  };

  return (
    <header css={headerStyle(pathNameCheck(location, "equalOR") ? 1 : scrollPosition)}>
      <div
        css={css`
          height: 60px;
          display: flex;
          align-items: center;
          padding: 0px 18.75vw;
          border-bottom: 1px solid #70737c38;

          @media only screen and (max-width: 375px) {
            padding: 0;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            width: 100%;
            gap: 80px;
            padding: 0px 20px;

            @media only screen and (max-width: 375px) {
              gap: unset;
              justify-content: space-between;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              cursor: pointer;
              height: 100%;

              svg {
                width: 134px;
                height: 24px;
              }

              @media only screen and (max-width: 375px) {
                svg {
                  width: 114px;
                  height: 24px;
                }
              }
            `}
          >
            {pathNameCheck(location, "unequalAND") &&
            window.outerWidth > 375 &&
            scrollPosition === 0 ? (
              <BiLogoDark
                onClick={() => {
                  history("/");
                }}
              />
            ) : (
              <BiLogo
                onClick={() => {
                  history("/");
                }}
              />
            )}
          </div>
          <div
            css={css`
              display: flex;
              width: 100%;
              justify-content: space-between;
              @media only screen and (max-width: 375px) {
                display: none;
              }
            `}
          >
            <ul css={LinkStyle}>
              {headerCont.map((item, idx) => (
                <li key={idx}>
                  <button
                    css={
                      pathNameCheck(location, "unequalAND") && scrollPosition === 0
                        ? LinkButtonDark(pathMatch(item.path))
                        : LinkButton(pathMatch(item.path))
                    }
                    onClick={() => {
                      history(item.path);
                    }}
                  >
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              cursor: pointer;
              display: none;

              @media only screen and (max-width: 375px) {
                display: inline-flex;
              }
            `}
          >
            <DropDownMenu />
          </div>
        </div>
      </div>
      <div
        css={css`
          height: ${pathMatch("/product") || pathMatch("/intro") || pathMatch("/companies")
            ? `50px`
            : `0px`};
          transition: all ease 0.3s;
          overflow: hidden;
          padding: 0px 18.75vw;
          display: flex;
          align-items: center;

          @media only screen and (max-width: 375px) {
            display: none;
          }
        `}
      >
        {pathMatch("/product") && (
          <>
            <div css={productHeader}>
              <div
                css={
                  scrollPosition === 0
                    ? productChipDark(pathMatchProduct("focus"))
                    : productChip(pathMatchProduct("focus"))
                }
                onClick={() => {
                  history("product/focus");
                }}
              >
                GAIA FOCUS
              </div>
              <div
                css={
                  scrollPosition === 0
                    ? productChipDark(pathMatchProduct("solomon"))
                    : productChip(pathMatchProduct("solomon"))
                }
                onClick={() => {
                  history("product/solomon");
                }}
              >
                GAIA SOLOMON
              </div>
              <div
                css={
                  scrollPosition === 0
                    ? productChipDark(pathMatchProduct("forte"))
                    : productChip(pathMatchProduct("forte"))
                }
                onClick={() => {
                  history("product/forte");
                }}
              >
                GAIA FORTE
              </div>
              <div
                css={
                  scrollPosition === 0
                    ? productChipDark(pathMatchProduct("project"))
                    : productChip(pathMatchProduct("project"))
                }
                onClick={() => {
                  history("product/project");
                }}
              >
                GAIA FORTE Project
              </div>
            </div>
          </>
        )}
        {pathMatch("/intro") && (
          <>
            <div css={introHeader}>
              <div
                css={
                  scrollPosition === 0
                    ? productChipDark(pathMatchProduct("about"))
                    : productChip(pathMatchProduct("about"))
                }
                onClick={() => {
                  history("intro/about");
                }}
              >
                TmaxBizAI
              </div>
              <div
                css={
                  scrollPosition === 0
                    ? productChipDark(pathMatchProduct("history"))
                    : productChip(pathMatchProduct("history"))
                }
                onClick={() => {
                  history("intro/history");
                }}
              >
                연혁
              </div>
              <div
                css={
                  scrollPosition === 0
                    ? productChipDark(pathMatchProduct("media"))
                    : productChip(pathMatchProduct("media"))
                }
                onClick={() => {
                  history("intro/media/news");
                }}
              >
                언론보도
              </div>
            </div>
          </>
        )}
        {pathMatch("/companies") && (
          <>
            <div css={companiesHeader}>
              <div
                css={scrollPosition === 0 ? productChipDark(true) : productChip(true)}
                onClick={() => {
                  history("companies");
                }}
              >
                Tmax Group
              </div>
              <div
                css={
                  scrollPosition === 0
                    ? productChipDark(pathMatchProduct(false))
                    : productChip(pathMatchProduct(false))
                }
                onClick={() => {
                  window.location.href = "https://tmaxcareers.ninehire.site/";
                }}
              >
                채용문의
              </div>
            </div>
          </>
        )}
      </div>
    </header>
  );
}

const headerStyle = (scrollPosition) => css`
  position: sticky;
  top: 0;
  display: flex;
  background-color: ${scrollPosition === 0 ? `rgba(17, 124, 233,0.08)` : `#FFFFFF`};

  transition: all ease 0.3s;
  flex-flow: column;

  z-index: 99;

  @media only screen and (max-width: 375px) {
    height: 52px;
    padding: 0;
    background-color: white;
  }
`;

const LinkStyle = css`
  display: flex;
  gap: 40px;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const LinkButton = (pathMatch) => css`
  border: none;
  background: none;
  color: ${pathMatch ? `#117CE9` : "#171719"};
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  cursor: pointer;
`;

const LinkButtonDark = (pathMatch) => css`
  border: none;
  background: none;
  color: ${pathMatch ? `#117CE9` : "#fff"};
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  cursor: pointer;
`;

const SelectBoxStyle = (scrollPosition) => css`
  display: inline-flex;
  align-items: center;

  div {
    border-radius: 6px;
  }

  div[class$="-optionStyle"] {
    top: 37px;
  }

  span[class$="family_site"] {
    color: ${scrollPosition === 0 ? `#FFF` : ``};
  }
  path[id$="Vector"] {
    stroke: ${scrollPosition === 0 ? `#FFF` : ``};
  }
`;

const productHeader = css`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 330px;
  gap: 40px;
`;

const introHeader = css`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 235px;
  gap: 40px;
`;

const companiesHeader = css`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 620px;
  gap: 40px;
`;

const productChip = (pathMatch) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  font-family: Pretendard;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  font-feature-settings: "ss10" on;

  color: ${pathMatch ? `#1C64F2` : `#797E86`};
  height: 38px;
  cursor: pointer;
`;

const productChipDark = (pathMatch) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  font-family: Pretendard;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  font-feature-settings: "ss10" on;
  line-height: 146%;

  color: ${pathMatch ? `#1C64F2` : `#D7D7D7`};
  height: 38px;
  cursor: pointer;
`;

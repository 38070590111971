import React from "react";
import { css } from "@emotion/react";
import { ReactComponent as BiLogo } from "../../resources/bi_logo_dark.svg";
import SelectBox from "../Common/selectBox";
import { useNavigate } from "react-router-dom";
import { display } from "@mui/system";

export default function Footer() {
  const history = useNavigate();

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
      `}
    >
      <footer css={FooterStyle}>
        <div
          css={css`
            display: block;
            text-align: -webkit-center;
          `}
        >
          <div css={BottomNavigation}>
            <div
              className="nav-title"
              onClick={() => {
                history("intro/about");
              }}
            >
              회사소개
            </div>
            <div
              className="nav-title"
              onClick={() => {
                history("product/focus");
              }}
            >
              상품소개
            </div>
            <div className="nav-title">공지사항</div>
            <div
              className="nav-title"
              onClick={() => {
                history("contact");
              }}
            >
              도입문의
            </div>
            <div
              className="nav-title"
              onClick={() => {
                history("companies");
              }}
            >
              그룹사
            </div>
          </div>
        </div>
        <div>
          <div
            css={css`
              display: inline-block;
              text-align: start;
              width: 426px;
            `}
          >
            <BiLogo width={100} height={13} />
            <div
              css={css`
                margin-top: 24px;
                margin-bottom: 16px;
              `}
            >
              <span css={FooterText}>
                {window.outerWidth > 375
                  ? "(주) 티맥스비즈에이아이 대표이사 | 오승훈 | 사업자 등록번호 : 306-86-01745"
                  : "(주) 티맥스비즈에이아이 대표이사 | 오승훈\r\n사업자 등록번호 : 306-86-01745"}
              </span>
              <br />
              <span css={FooterText}>
                {"본사 : 경기도 성남시 분당구 황새울로258번길 31(수내동)"}
              </span>
              <br />
              <span css={FooterText}>{"R&D 센터 : 경기도 성남시 분당구 구미로 8"}</span>
              <br />
              <span css={FooterText}>
                {"이메일 : tmaxbizai_info@tmax.co.kr  |  대표전화 : 031-776-9186"}
              </span>
            </div>
            <span css={FooterText}>{"Copyright © TmaxBizAI Inc. All Rights Reserved."}</span>
          </div>
        </div>
      </footer>
    </div>
  );
}

const FooterStyle = css`
  height: 324px;
  display: flex;
  background-color: #000000;
  padding: 48px 18.75vw;
  box-sizing: border-box;

  justify-content: center;
  max-width: 100vw;
  width: 100vw;
  flex-flow: column;

  @media only screen and (max-width: 375px) {
    flex-flow: column;
    padding-top: 10px;
    padding: 50px 20px;
    height: 378px;
  }
`;

const FooterText = css`
  color: #d7d7d7;

  font-family: Pretendard;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
`;

const BottomNavigation = css`
  display: flex;
  justify-content: space-between;
  max-width: 426px;
  margin-bottom: 32px;

  .nav-title {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
`;

import React from "react";
import { css } from "@emotion/react";
import { ReactComponent as YoutubeIcon } from "../../resources/Media/icon_youtube.svg";
import { ReactComponent as FacebookIcon } from "../../resources/Media/icon_facebook.svg";
import { ReactComponent as BlogIcon } from "../../resources/Media/icon_blog.svg";
import { ReactComponent as PostIcon } from "../../resources/Media/icon_post.svg";
import { ReactComponent as ArrowUpRight } from "../../resources/Media/ArrowUpRight.svg";

const SocialPage = () => {
  return (
    <>
      <div css={Wrapper}>
        <div className="sub-header">
          다양한 채널을 통해
          <br />
          티맥스비즈아이의 소식을 만나보세요.
        </div>

        <div className="social-icon-wrapper">
          <div
            css={[
              SocialContainer,
              css`
                background: #fa141b;
              `,
            ]}
          >
            <div className="logo">
              <YoutubeIcon />
            </div>
            <div
              className="link-area"
              onClick={() => {
                window.location.href = "https://www.youtube.com/channel/UCSL_WpbQKwxFZpdMSqRaRTg ";
              }}
            >
              <span>유튜브 채널 바로가기</span>
              <div className="icon">
                <ArrowUpRight />
              </div>
            </div>
          </div>
          <div
            css={[
              SocialContainer,
              css`
                background: #1d7cef;
              `,
            ]}
          >
            <div className="logo">
              <FacebookIcon />
            </div>
            <div
              className="link-area"
              onClick={() => {
                window.location.href = "https://www.facebook.com/TmaxHQ";
              }}
            >
              <span>페이스북 페이지 바로가기</span>
              <div className="icon">
                <ArrowUpRight />
              </div>
            </div>
          </div>
          <div
            css={[
              SocialContainer,
              css`
                background: #31c55f;
              `,
            ]}
          >
            <div className="logo">
              <BlogIcon />
            </div>
            <div
              className="link-area"
              onClick={() => {
                window.location.href = "https://blog.naver.com/tmaxhq";
              }}
            >
              <span>네이버 블로그 바로가기</span>
              <div className="icon">
                <ArrowUpRight />
              </div>
            </div>
          </div>
          <div
            css={[
              SocialContainer,
              css`
                background: #36dc7f;
              `,
            ]}
          >
            <div className="logo">
              <PostIcon />
            </div>
            <div
              className="link-area"
              onClick={() => {
                window.location.href = "https://post.naver.com/tmaxhq";
              }}
            >
              <span>네이버 포스트 바로가기</span>
              <div className="icon">
                <ArrowUpRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialPage;

const Wrapper = css`
  width: inherit;
  border-top: 1px solid var(--Line-Normal, #e5e5e5);
  padding: 90px 17vw 150px 18.75vw;

  @media only screen and (max-width: 375px) {
    padding: 24px 20px;
    gap: 0;
  }

  .sub-header {
    width: inherit;
    color: #1e1e1e;
    text-align: start;

    font-family: Pretendard;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 1rem;
    }
  }

  .social-icon-wrapper {
    width: inherit;
    display: flex;
    gap: 24px;
    margin-top: 64px;
    flex-wrap: wrap;

    @media only screen and (max-width: 375px) {
      margin-top: 24px;
    }
  }
`;

const SocialContainer = css`
  display: inline-flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 166px;
  width: 282px;
  height: 282px;
  box-sizing: border-box;
  flex-shrink: 0;

  border-radius: 20px;

  @media only screen and (max-width: 375px) {
    width: 335px;
    height: auto;
    gap: 24px;
    flex-flow: column-reverse;
    padding: 24px;

    .logo {
      width: 100%;
      justify-content: end;
      display: flex;
    }
  }

  .link-area {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 375px) {
      justify-content: unset;
      gap: 24px;
    }

    span {
      color: #fff;
      font-family: Pretendard;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
      }
    }
  }
`;

import React from "react";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import Banner from "../../resources/Product/forte/forte_banner.png";
import { ReactComponent as ForteLogo } from "../../resources/Product/forte/forte_logo.svg";
import { ReactComponent as MetionImg } from "../../resources/Product/forte/img-mention.svg";
import { ReactComponent as ForteColoredLogo } from "../../resources/Product/forte/bi_tmax_forte_dark.svg";
import { ReactComponent as FocusLogo } from "../../resources/Product/focus/bi_focus_logo_dark.svg";
import {
  FocusVector,
  ForteVector,
  MobileVector,
  AiCashFlowVectorActive,
  AiRiskVectorUnActive,
  AiCashFlowVectorUnActive,
  AiRiskVectorActive,
  AICashFlowVectorActiveMobile,
  AICashFlowVectorUnActiveMobile,
  AiRiskVectorUnActiveMobile,
  AiRiskVectorActiveMobile,
} from "./vector";

import { ReactComponent as HrEroImg } from "../../resources/Product/forte/hr_ero_img.svg";
import { ReactComponent as HrEroImgMobile } from "../../resources/Product/forte/hr_ero_img_mobile.svg";
import DetailBannerImg from "../../resources/Product/forte/forte_detail_banner.png";

const FortePage = () => {
  const history = useNavigate();
  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const [decisionSelect, setDecisionSelect] = React.useState({ profit: true, card: 0 });

  function useInterval(callback, delay) {
    const savedCallback = React.useRef();
    React.useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const changeDecision = (cardVal) => {
    let val = cardVal + 1;
    if (val > 2) {
      setDecisionSelect({ profit: !decisionSelect.profit, card: 0 });
    } else {
      setDecisionSelect({ ...decisionSelect, card: val });
    }
  };

  useInterval(() => {
    changeDecision(decisionSelect.card);
  }, 2000);

  return (
    <>
      <div css={wrapperStyle}>
        <div css={imgWrapper}>
          <img src={Banner} />
          <div css={textWrapper}>
            <ForteLogo />
            <div className="head-wrapper">AI 기반 기업 경영 최적화 솔루션</div>
            <div className="child-wrapper">
              {window.outerWidth > 375 ? (
                <>
                  업무 영역 별 특화 AI 모델 운영을 통한 경영 최적화를 실현합니다.
                  <br />
                  기업의 상황에 맞게 서비스를 확장•연동이 가능합니다.
                </>
              ) : (
                <>
                  업무 영역 별 특화 AI 모델 운영을 통한 경영 최적화를 <br />
                  실현합니다. 기업의 상황에 맞게 서비스를
                  <br /> 확장•연동이 가능합니다.
                </>
              )}
            </div>
          </div>
        </div>

        <div css={ForteIntroduction}>
          <div className="intro-title">
            중요한 의사 결정은 포르테와 함께!
            <br /> 기업 운영 최적화는 FORTE
          </div>
          <div className="intro-diagram">
            <div className="diagram-wrapper">
              <div className="diagram">SuperApp 기반 ERO</div>
              <div
                className="diagram"
                css={css`
                  ${window.outerWidth > 375 && `background: rgba(176, 208, 255, 0.1) !important`};
                `}
              >
                FORTE HR Optimization
              </div>
              <div className="diagram">FORTE FI Optimization</div>
            </div>

            <div className="complain-container">
              <div className="complain-card">
                <MetionImg />
                <div className="card-text-area">
                  <span className="complain-number">01</span>
                  <span className="complain-text">
                    생존이 걸린 기업경영 홀로
                    <br />
                    의사결정이 힘들어요.
                  </span>
                </div>
              </div>
              <div className="complain-card">
                <MetionImg />
                <div className="card-text-area">
                  <span className="complain-number">02</span>
                  <span className="complain-text">
                    직무와 역량에 맞는
                    <br />
                    인력 배치가 어려워요.
                  </span>
                </div>
              </div>
              <div className="complain-card">
                <MetionImg />
                <div className="card-text-area">
                  <span className="complain-number">03</span>
                  <span className="complain-text">
                    미래 리스크에 대응하여
                    <br />
                    기업 자산관리를 하고 싶어요!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div css={OptimizationWrapper}>
          <div className="optimize-text-area">
            <span className="optimize-title">SuperApp 기반 경영 최적화 </span>
            <span className="optimize-sub-title">
              {window.outerWidth > 375 ? (
                <>
                  Tmax그룹의 기술력을 바탕으로 경영최적화를 위한 통합 아키텍처를 제공 합니다.
                  <br />
                  IT 투자 여력이 없는 작은 기업 또한 AI 경영이 가능할 수 있도록 업무 영역 별 특화 AI
                  모델을 운영합니다.
                </>
              ) : (
                <>
                  Tmax그룹의 기술력을 바탕으로 경영 최적화를 위한
                  <br />
                  통합 아키텍처를 제공 합니다.
                  <br />
                  IT 투자 여력이 없는 작은 기업 또한 AI 경영이 <br />
                  가능할 수 있도록 업무 영역 별 특화 AI 모델을 운영합니다.
                </>
              )}
            </span>
          </div>

          <div className="optimize-logo-area">
            <div className="product-logo-area">
              <div className="forte-container">
                <ForteColoredLogo />
                {window.outerWidth > 375 ? <ForteVector /> : <MobileVector />}
              </div>
              <div className="focus-container">
                <FocusLogo />
                {window.outerWidth > 375 ? <FocusVector /> : <MobileVector />}
              </div>
            </div>
            <div className="service-container">
              <div className="left-wrapper">
                <div className="service-wrapper">인적</div>
                <div className="service-wrapper">재무</div>
                <div className="service-wrapper">물적</div>
              </div>
              <div className="right-wrapper">
                <div
                  className="service-wrapper"
                  css={css`
                    background: #bedff7 !important;
                    color: #216fe4 !important;
                  `}
                >
                  상품
                </div>
                <div
                  className="service-wrapper"
                  css={css`
                    background: #bedff7 !important;
                    color: #216fe4 !important;
                  `}
                >
                  고객
                </div>
              </div>
            </div>

            <div className="optimize-first">
              <span>업무 영역별 특화 AI모델 운영</span>
            </div>
            <div className="optimize-second">전사 통합 AI 데이터</div>
            <div className="optimize-third">
              SuperApp 기반 <br />
              AI Digital Tranformation
            </div>
          </div>
        </div>

        <div css={HrEroContainer}>
          <div className="HrEro-text-area">
            <div className="HrEro-title">
              HR ERO 기반 {window.outerWidth <= 375 && <br />}인적자원 최적화
            </div>
            <div className="HrEro-sub-title">
              {window.outerWidth > 375 ? (
                <>
                  인력의 경험과 역량, 직무 중 발생하는 활동들을 데이터화하여 연계 및 통합 합니다.
                  <br />
                  AI 분석을 통해 프로젝트의 포지션 별 적합 인재를 선별하며, 인력의 과부족을
                  예측합니다.
                </>
              ) : (
                <>
                  인력의 경험과 역량, 직무 중<br />
                  발생하는 활동들을 데이터화하여 연계 및 통합 합니다.
                  <br />
                  AI 분석을 통해 프로젝트의 포지션 별 적합 인재를 선별하며,
                  <br />
                  인력의 과부족을 예측합니다.
                </>
              )}
            </div>
          </div>
          {window.outerWidth > 375 ? <HrEroImg /> : <HrEroImgMobile />}
        </div>

        <div css={DecisionContainer}>
          <div className="decision-text-area">
            <span className="decision-title">
              {window.outerWidth > 375
                ? "  기업 자금 관리에 대한 최적의 의사결정"
                : "  기업 자금 관리에 대한\r\n최적의 의사결정"}
            </span>
            <span className="decision-sub-title">
              {window.outerWidth > 375
                ? "내·외부 재무 데이터 연동을 통해 기업의 실시간 현금 흐름 파악 및 장부 관리가 가능합니다.\r\nAI 현금흐름 예측을 통해 기업 자금 관리에 대한 최적의 의사결정을 지원합니다."
                : "내·외부 재무 데이터 연동을 통해\r\n기업의 실시간 현금 흐름 파악 및 장부 관리가 가능합니다.\r\nAI 현금흐름 예측을 통해 기업 자금 관리에 대한\r\n최적의 의사결정을 지원합니다."}
            </span>
          </div>
          {window.outerWidth > 375 ? (
            <div className="decision-logo-area">
              {decisionSelect.profit ? <AiCashFlowVectorActive /> : <AiCashFlowVectorUnActive />}
              {!decisionSelect.profit ? <AiRiskVectorActive /> : <AiRiskVectorUnActive />}

              <div
                css={css`
                  display: inline-flex;
                  align-items: flex-start;
                  gap: 24px;
                  position: absolute;
                  bottom: 12px;
                  left: 12px;
                `}
              >
                <div css={CashFlowCard(decisionSelect.card === 0, true, decisionSelect.profit)}>
                  사업 재투자
                </div>
                <div css={CashFlowCard(decisionSelect.card === 1, true, decisionSelect.profit)}>
                  채무 상환
                </div>
                <div css={CashFlowCard(decisionSelect.card === 2, true, decisionSelect.profit)}>
                  단기 <br />
                  금융 투자
                </div>
              </div>

              <div
                css={css`
                  display: inline-flex;
                  align-items: flex-start;
                  gap: 24px;
                  position: absolute;
                  bottom: 12px;
                  right: 12px;
                `}
              >
                <div
                  css={CashFlowCard(
                    decisionSelect.card === 0,
                    false,
                    decisionSelect.profit === false
                  )}
                >
                  차입
                </div>
                <div
                  css={CashFlowCard(
                    decisionSelect.card === 1,
                    false,
                    decisionSelect.profit === false
                  )}
                >
                  채권발행
                </div>
                <div
                  css={CashFlowCard(
                    decisionSelect.card === 2,
                    false,
                    decisionSelect.profit === false
                  )}
                >
                  증자
                </div>
              </div>

              <div css={ProfitLossIcon(decisionSelect.profit, true)}>이익 발생</div>
              <div css={ProfitLossIcon(decisionSelect.profit === false, false)}>결손발생</div>

              <div
                css={css`
                  display: flex;
                  flex-flow: row;
                  position: absolute;
                  top: calc(50% - 118px);
                  left: calc(50% - 200px);
                `}
              >
                <div css={DecisionDiagram}>DecisionDiagram</div>
                <div css={DecisionDiagram}>
                  AI Cashflow <br />
                  Forecasting
                </div>
              </div>
            </div>
          ) : (
            <div className="decision-logo-area">
              {decisionSelect.profit ? (
                <AICashFlowVectorActiveMobile />
              ) : (
                <AICashFlowVectorUnActiveMobile />
              )}
              {!decisionSelect.profit ? (
                <AiRiskVectorActiveMobile />
              ) : (
                <AiRiskVectorUnActiveMobile />
              )}

              {decisionSelect.profit ? (
                <div
                  css={css`
                    display: inline-flex;
                    align-items: flex-start;
                    gap: 12px;
                    position: absolute;
                    bottom: 25px;
                    left: 55px;
                  `}
                >
                  <div css={CashFlowCard(decisionSelect.card === 0, true, decisionSelect.profit)}>
                    {"사업\r\n재투자"}
                  </div>
                  <div css={CashFlowCard(decisionSelect.card === 1, true, decisionSelect.profit)}>
                    채무 상환
                  </div>
                  <div css={CashFlowCard(decisionSelect.card === 2, true, decisionSelect.profit)}>
                    {"단기\r\n금융투자"}
                  </div>
                </div>
              ) : (
                <div
                  css={css`
                    display: inline-flex;
                    align-items: flex-start;
                    gap: 12px;
                    position: absolute;
                    bottom: 25px;
                    left: 35px;
                  `}
                >
                  <div
                    css={CashFlowCard(
                      decisionSelect.card === 0,
                      false,
                      decisionSelect.profit === false
                    )}
                  >
                    차입
                  </div>
                  <div
                    css={CashFlowCard(
                      decisionSelect.card === 1,
                      false,
                      decisionSelect.profit === false
                    )}
                  >
                    채권발행
                  </div>
                  <div
                    css={CashFlowCard(
                      decisionSelect.card === 2,
                      false,
                      decisionSelect.profit === false
                    )}
                  >
                    증자
                  </div>
                </div>
              )}
              <div css={ProfitLossIcon(decisionSelect.profit, true)}>이익 발생</div>
              <div css={ProfitLossIcon(decisionSelect.profit === false, false)}>결손발생</div>

              <div
                css={css`
                  display: flex;
                  flex-flow: row;
                  position: absolute;

                  top: calc(50% - 80px);
                  left: calc(50% - 120px);
                `}
              >
                <div css={DecisionDiagram}>{"AI Risk\r\nSimulation"}</div>
                <div css={DecisionDiagram}>{"AI Cashflow\r\nForecasting"}</div>
              </div>
            </div>
          )}
        </div>

        <div css={DetailBanner}>
          <img src={DetailBannerImg} />
          <div className="text-area">
            <span className="title">
              중요한 의사 결정은 포르테와 함께!
              <br />
              기업 운영 최적화는{" "}
              <ForteLogo
                css={css`
                  width: 233px;
                  height: 24px;

                  @media only screen and (max-width: 375px) {
                    width: 136px;
                    height: 14px;
                  }
                `}
              />
            </span>
            <div
              className="detail-btn"
              onClick={() => {
                history("/contact");
              }}
            >
              FORTE 시작하기
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FortePage;

const wrapperStyle = css`
  width: inherit;
  position: relative;
`;

const imgWrapper = css`
  position: relative;
  width: 100%;
  height: 780px;
  overflow: hidden;

  @media only screen and (max-width: 375px) {
    height: 480px;
  }

  .img-center {
    text-align: center;
    position: absolute;
    top: 0;
    right: -200%;
    bottom: 0;
    left: -200%;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const textWrapper = css`
  position: absolute;
  left: 360px;
  top: 27.82%;

  @media only screen and (max-width: 375px) {
    font-size: 1.5rem;
    left: 20px;
  }

  display: inline-flex;
  flex-flow: column;

  text-align: start;

  .head-wrapper {
    color: white;
    margin-top: 60px;

    color: #fff;
    font-family: Pretendard;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .child-wrapper {
    color: white;
    margin-top: 32px;

    color: var(--Text-Assistive, #d7d7d7);
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;

    @media only screen and (max-width: 375px) {
      font-size: 1rem;
    }
  }
`;

const ForteIntroduction = css`
  display: flex;
  padding: 150px 360px 200px 360px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
  align-self: stretch;
  background: #f3f6f8;
  position: relative;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px 128px 20px;
  }

  .intro-title {
    color: #1e1e1e;
    font-family: Pretendard;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-align: start;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .intro-diagram {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    .diagram-wrapper {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .diagram {
        display: inline-flex;
        width: 280px;
        height: 280px;
        padding: 129px 94px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 999px;
        border: 2px solid #1c64f2;

        background: rgba(28, 100, 242, 0.1);

        mix-blend-mode: multiply;

        color: var(--Primary-Normal, #1c64f2);
        text-align: center;

        font-family: Pretendard;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        margin: 0 -12px;

        @media only screen and (max-width: 375px) {
          width: 160px;
          height: 160px;
          padding: 55px 32px;
          font-size: 1.125rem;
          margin: -24px -12px;
          border: 1px solid #1c64f2;
        }
      }
    }
  }
  .complain-container {
    display: flex;
    //width: 100vw;
    width: 1920px;
    height: 338px;
    padding: 72px 360px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-flow: row;
    position: absolute;
    bottom: -170px;
    z-index: 1;

    @media only screen and (max-width: 375px) {
      padding: 72px 20px;
      flex-flow: column;
      bottom: -410px;
      height: fit-content;
    }

    .complain-card {
      position: relative;
      display: flex;
      padding: 24px 24px 24px 96px;
      align-items: center;
      gap: 24px;
      flex: 1 0 0;
      align-self: stretch;

      border-radius: 20px;
      background: #fff;

      box-shadow: 0px 4px 50px 0px rgba(50, 99, 194, 0.1);
      width: 100%;
      height: 100%;

      @media only screen and (max-width: 375px) {
        padding: 12px 16px 12px 96px;
        gap: 16px;
      }

      svg {
        position: absolute;
        top: 0;
        left: 12px;

        @media only screen and (max-width: 375px) {
          width: 64px;
          height: 58.776px;
        }
      }

      .card-text-area {
        display: flex;
        flex-flow: column;
        padding: 12px 0px;
        gap: 10px;

        .complain-number {
          color: #1c64f2;
          font-family: Pretendard;
          font-size: 2rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          text-align: start;

          @media only screen and (max-width: 375px) {
            font-size: 1.125rem;
          }
        }

        .complain-text {
          align-self: stretch;
          color: #aaa;

          font-family: Pretendard;
          font-size: 1rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          text-align: start;

          @media only screen and (max-width: 375px) {
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 150%;
          }
        }
      }
    }
  }
`;

const OptimizationWrapper = css`
  display: flex;
  padding: 320px 360px 150px 360px;
  gap: 60px;
  flex-flow: column;

  @media only screen and (max-width: 375px) {
    padding: 410px 20px 48px 20px;
  }

  .optimize-text-area {
    display: flex;
    flex-flow: column;
    gap: 16px;

    .optimize-title {
      color: #1e1e1e;

      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 1.5rem;
      }
    }
    .optimize-sub-title {
      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
        line-height: 150%;
      }
    }
  }

  .optimize-logo-area {
    display: flex;
    padding: 48px 120px 72px 120px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    flex-flow: column;

    border-radius: 20px;
    background: #f1f4f8;

    @media only screen and (max-width: 375px) {
      padding: 32px 20px;
      width: 100%;
    }

    .product-logo-area {
      display: flex;
      gap: 24px;
      width: 100%;

      @media only screen and (max-width: 375px) {
        gap: 8px;
      }

      .forte-container {
        width: 567px;
        display: flex;
        flex-flow: column;
        gap: 41px;
        align-items: center;
        width: 100%;

        @media only screen and (max-width: 375px) {
          width: calc(100% - 143.5px);
          svg:nth-of-type(1) {
            width: 70.4px;
            height: 40px;
          }
        }
      }

      .focus-container {
        max-width: 370px;
        display: flex;
        flex-flow: column;
        gap: 41px;
        align-items: center;

        @media only screen and (max-width: 375px) {
          width: 143.5px;
          svg:nth-of-type(1) {
            width: 75.18px;
            height: 40px;
          }
        }
      }
    }

    .service-container {
      display: flex;
      flex-flow: row;
      gap: 24px;
      width: 100%;

      @media only screen and (max-width: 375px) {
        gap: 12px;
      }

      .left-wrapper {
        display: inline-flex;
        gap: 24px;
        width: calc(100% - 370px);

        @media only screen and (max-width: 375px) {
          flex-flow: column;
          width: 141.5px;
          gap: 12px;
        }
      }

      .right-wrapper {
        display: inline-flex;
        width: 370px;
        gap: 24px;

        @media only screen and (max-width: 375px) {
          flex-flow: column;
          width: 141.5px;
          gap: 12px;
        }
      }

      .service-wrapper {
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;

        border-radius: 16px;
        background: #bed4f7;

        color: #216fe4;

        text-align: center;
        font-family: Pretendard;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;

        @media only screen and (max-width: 375px) {
          padding: 8px 16px;
          font-size: 0.875rem;
          border-radius: 12px;
        }
      }
    }

    .optimize-first {
      display: flex;
      padding: 24px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      border-radius: 16px;
      border: 2px solid #1d68fe;

      background: #fff;

      @media only screen and (max-width: 375px) {
        border-radius: 12px;
        padding: 16px;
      }

      span {
        text-align: center;
        font-family: Pretendard;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;

        background: linear-gradient(91deg, #1bc8ff -0.12%, #3151e6 50%, #1d07a5 100.12%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media only screen and (max-width: 375px) {
          font-size: 1rem;
        }
      }
    }

    .optimize-second {
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      border-radius: 16px;
      background: #1c64f2;

      color: #fff;

      text-align: center;

      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      @media only screen and (max-width: 375px) {
        font-size: 1rem;
        padding: 16px;
        border-radius: 12px;
      }
    }

    .optimize-third {
      display: flex;
      padding: 16px 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      border-radius: 12px;
      background: linear-gradient(91deg, #1b32ff -0.12%, #071d80 50%, #2e0683 100.12%);

      color: #fff;

      text-align: center;
      font-family: Pretendard;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      @media only screen and (max-width: 375px) {
        font-size: 1.25rem;
        padding: 16px 24px;
        border-radius: 12px;
      }
    }
  }
`;

const HrEroContainer = css`
  display: flex;
  padding: 150px 360px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  align-self: stretch;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
  }

  .HrEro-text-area {
    display: flex;
    align-items: start;
    flex-flow: column;
    width: 100%;
    gap: 16px;

    .HrEro-title {
      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      @media only screen and (max-width: 375px) {
        font-size: 1.5rem;
        text-align: start;
      }
    }

    .HrEro-sub-title {
      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
      }
    }
  }
`;

const DecisionContainer = css`
  display: flex;
  padding: 150px 360px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  align-self: stretch;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
    gap 32px;
  }

  .decision-text-area {
    display: flex;
    align-items: start;
    flex-flow: column;
    width: 100%;
    gap: 16px;

    .decision-title {
      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      @media only screen and (max-width: 375px) {
        font-size: 1.5rem;
        text-align:start;
      }
    }

    .decision-sub-title {
      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
        text-align:start;
      }
    }
  }

  .decision-logo-area {
    width: 955.724px;
    height: 588px;
    flex-shrink: 0;
    position: relative;

    
    @media only screen and (max-width: 375px) {
      width: 355px;
      height: 420px;
    }
  }
`;

const CashFlowCard = (active, profit, profitSelect) => css`
  display: flex;
  width: 120px;
  height: 120px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media only screen and (max-width: 375px) {
    width: 80px;
    height: 80px;
    font-size: 0.813rem;
  }

  border-radius: 999px;
  border: ${profitSelect
    ? profit
      ? active
        ? `2px solid #ff6c6c`
        : `1px solid #FFAFAF`
      : active
        ? `2px solid #00B3FF`
        : `1px solid #66D1FF`
    : ` 1px solid #CCC`};
  background: ${profitSelect
    ? profit
      ? active
        ? `#FFE4E4`
        : `#FFF`
      : active
        ? `#ECF9FF`
        : `#FFF`
    : ` #FFF`};

  color: ${profitSelect ? (profit ? `#FF2C2C` : `#27BEFF;`) : ` #AAA`};
  text-align: center;
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: ${active ? `700` : `400`};
  line-height: ${active ? (profit ? `140%` : `150%`) : `150%`};

  transition: all ease 0.3s;
`;

const ProfitLossIcon = (active, profit) => css`
  display: flex;
  width: 144px;
  height: 144px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;

  border-radius: 999px;
  background: ${active ? (profit ? `#ff7373` : `#00B3FF`) : `rgba(196, 214, 224, 0.20)`};

  color: ${active ? `#fff` : `#AAA`};

  text-align: center;
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  position: absolute;
  top: 11px;
  left: ${profit ? `111px` : ``};
  right: ${profit ? `` : `111.72px`};

  transition: all ease 0.3s;

  @media only screen and (max-width: 375px) {
    width: 80px;
    height: 80px;
    font-size: 0.875rem;
    top: 10px;

    left: ${profit ? `30px` : ``};
    right: ${profit ? `` : `29px`};
  }
`;

const DecisionDiagram = css`
  display: flex;
  width: 224px;
  height: 224px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 999px;
  background: #1c64f2;

  mix-blend-mode: multiply;

  color: #fff;

  text-align: center;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  margin: 0 -12px;

  @media only screen and (max-width: 375px) {
    width: 144px;
    height: 144px;
    font-size: 1rem;
  }
`;

const DetailBanner = css`
  position: relative;
  @media only screen and (max-width: 375px) {
    height: 282px;
    overflow: hidden;
  }
  .text-area {
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;

    gap: 48px;

    @media only screen and (max-width: 375px) {
      gap: 24px;
      width: 100%;
    }

    .title {
      color: #fff;

      text-align: center;
      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      @media only screen and (max-width: 375px) {
        font-size: 1.125rem;
      }
    }

    .detail-btn {
      display: flex;
      width: 300px;
      height: 48px;
      min-width: 131px;
      padding: 10px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      border-radius: 4px;
      background: #fff;

      color: var(--Text-Normal, #1e1e1e);
      text-align: center;
      font-family: Pretendard;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      cursor: pointer;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
        padding: 8px 24px;
        width: 144px;
        font-weight: 600;
      }
    }
  }
`;

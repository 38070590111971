import React from "react";
import { css } from "@emotion/react";
import { ReactComponent as CompaniesBanner } from "../../resources/Companies/companies_banner.svg";
import { ReactComponent as CompaniesBannerMob } from "../../resources/Companies/CompaniesBannerMob.svg";

import { ReactComponent as BiLogoDark } from "../../resources/bi_logo_dark.svg";
import { ReactComponent as TmaxLogo } from "../../resources/About/tmax_logo.svg";

import { ReactComponent as TmaxCompanyLogo } from "../../resources/Companies/tmax_company_logo.svg";
import { ReactComponent as CoreAI } from "../../resources/Companies/CoreAI.svg";
import { ReactComponent as TmaxTibero } from "../../resources/Companies/TmaxTibero.svg";
import { ReactComponent as TmaxCloud } from "../../resources/Companies/TmaxCloud.svg";
import { ReactComponent as TmaxGaia } from "../../resources/Companies/TmaxGaia.svg";
import { ReactComponent as TmaxEduAI } from "../../resources/Companies/TmaxEduAI.svg";
import { ReactComponent as TmaxMetaAI } from "../../resources/Companies/TmaxMetaAI.svg";
import { ReactComponent as TmaxBizAI } from "../../resources/Companies/TmaxBizAI.svg";
import { ReactComponent as TmaxFinAI } from "../../resources/Companies/TmaxFinAI.svg";
import { ReactComponent as TmaxShopAI } from "../../resources/Companies/TmaxShopAI.svg";

import { ReactComponent as ArrowUpRight } from "../../resources/Companies/ArrowUpRight.svg";

const Companies = () => {
  return (
    <div css={wrapperStyle}>
      <div css={contentStyle}>
        <div css={bannerContainer}>
          {window.outerWidth > 375 ? <CompaniesBanner /> : <CompaniesBannerMob />}

          <div className="banner-content">
            <div className="banner-content-row">
              <BiLogoDark /> 는
            </div>
            <div className="banner-content-row">세계적인 기술력의 집합체,</div>
            <div className="banner-content-row">
              <TmaxLogo /> 와 함께합니다
            </div>
            <div className="banner-content-sub">
              {
                "원천 기술을 기반으로 한 새로운 혁신과 끊임없는 도전\r\n새로운 IT 세상을 만드는 것, 티맥스의 궁극적인 목표입니다."
              }
            </div>
          </div>
        </div>

        <div css={companiesContainer}>
          <div className="companies-card">
            <div className="logo-area">
              <TmaxCompanyLogo />
            </div>
            <div className="intro-text">세계적인 기술력의 집합체 기업</div>
            <div className="sub-text">
              독보적이고 강력한 원천기술을 기반으로 SuperApp을 제공합니다.
            </div>
            <div className="btn-area">
              <div
                className="card-button"
                onClick={() => {
                  window.location.href = "https://www.tmax.co.kr/";
                }}
              >
                <ArrowUpRight />
              </div>
            </div>
          </div>
          <div className="companies-card">
            <div className="logo-area">
              <CoreAI />
            </div>
            <div className="intro-text">AI · Interaction 플랫폼 전문 기업</div>
            <div className="sub-text">
              AI와 App 통합을 바탕으로 새로운 비즈니스 AI를 제공합니다.
            </div>
            <div className="btn-area">
              <div
                className="card-button"
                onClick={() => {
                  window.location.href = "https://tmaxai.co.kr/";
                }}
              >
                <ArrowUpRight />
              </div>
            </div>
          </div>
          <div className="companies-card">
            <div className="logo-area">
              <TmaxTibero />
            </div>
            <div className="intro-text">Total Data Technology 전문 기업</div>
            <div className="sub-text">
              기업의 강력한 데이터 경쟁력을 확보할 수 있는 기반을 제공합니다.
            </div>
            <div className="btn-area">
              <div
                className="card-button"
                onClick={() => {
                  window.location.href = "https://www.tmaxtibero.com/main.do";
                }}
              >
                <ArrowUpRight />
              </div>
            </div>
          </div>
          <div className="companies-card">
            <div className="logo-area">
              <TmaxCloud />
            </div>
            <div className="intro-text">Cloud 솔루션 전문 기업</div>
            <div className="sub-text">
              Digital Transformation을 위한 클라우드 환경을 제공합니다.
            </div>
            <div className="btn-area">
              <div
                className="card-button"
                onClick={() => {
                  window.location.href = "https://www.tmaxcloud.com/";
                }}
              >
                <ArrowUpRight />
              </div>
            </div>
          </div>
          <div className="companies-card">
            <div className="logo-area">
              <TmaxGaia />
            </div>
            <div className="intro-text">Office · All-in-One 개발 플랫폼 전문 기업</div>
            <div className="sub-text">
              {
                "차세대 오피스 소프트웨어와 문서관리 솔루션, 그리고 노코드 플랫폼을\r\n혁신하는 IT솔루션을 제공합니다."
              }
            </div>
            <div className="btn-area">
              <div
                className="card-button"
                onClick={() => {
                  window.location.href = "http://tmaxoffice.co.kr/";
                }}
              >
                <ArrowUpRight />
              </div>
            </div>
          </div>
          <div className="companies-card">
            <div className="logo-area">
              <TmaxEduAI />
            </div>
            <div className="intro-text">EduTech Service 전문 기업</div>
            <div className="sub-text">
              {"AI 기반의 교육 플랫폼, 학습 플랫폼, 앱 북 플랫폼을 제공합니다."}
            </div>
            <div className="btn-area">
              <div
                className="card-button"
                onClick={() => {
                  window.location.href = "https://www.tmax.co.kr/tmaxrg";
                }}
              >
                <ArrowUpRight />
              </div>
            </div>
          </div>
          <div className="companies-card">
            <div className="logo-area">
              <TmaxMetaAI />
            </div>
            <div className="intro-text">Metaverse 플랫폼 전문 기업</div>
            <div className="sub-text">
              {"메타버스와 Vision AI 기술이 결합된 3D 스튜디오를 제공합니다."}
            </div>
            <div className="btn-area">
              <div
                className="card-button"
                onClick={() => {
                  window.location.href = "https://www.tmaxmetaverse.com/";
                }}
              >
                <ArrowUpRight />
              </div>
            </div>
          </div>
          <div className="companies-card">
            <div className="logo-area">
              <TmaxBizAI />
            </div>
            <div className="intro-text">AI 기반 비즈니스 솔루션 전문 기업</div>
            <div className="sub-text">
              {"ERP / AI / Big Data 핵심 기술 기반 비즈니스 솔루션을 제공합니다."}
            </div>
            <div className="btn-area">
              <div
                className="card-button"
                onClick={() => {
                  window.location.href = "https://tmaxbizai.com/";
                }}
              >
                <ArrowUpRight />
              </div>
            </div>
          </div>
          <div className="companies-card">
            <div className="logo-area">
              <TmaxFinAI />
            </div>
            <div className="intro-text">금융 IT 솔루션 전문 기업</div>
            <div className="sub-text">
              {"금융 시장의 선순환을 제공하는 금융 IT 솔루션 기반을 제공합니다."}
            </div>
            <div className="btn-area">
              <div
                className="card-button"
                onClick={() => {
                  window.location.href = "https://www.tmax.co.kr/tmaxfintech";
                }}
              >
                <ArrowUpRight />
              </div>
            </div>
          </div>
          <div className="companies-card">
            <div className="logo-area">
              <TmaxShopAI />
            </div>
            <div className="intro-text">Commerce 플랫폼 전문 기업</div>
            <div className="sub-text">
              {"판매자와 소비자 모두가 상생할 수 있는 새로운 경험을 제공합니다."}
            </div>
            <div className="btn-area">
              <div
                className="card-button"
                onClick={() => {
                  window.location.href = "https://tmaxcommerce.com/";
                }}
              >
                <ArrowUpRight />
              </div>
            </div>
          </div>
          {/** */}
        </div>
      </div>
    </div>
  );
};

export default Companies;

const wrapperStyle = css`
  width: inherit;
  margin-top: -61px;
  background: #fff;
  text-align: center;

  @media only screen and (max-width: 375px) {
    margin-top: 0px;
  }
`;

const contentStyle = css`
  width: 100%;
  padding: 80px 360px;

  @media only screen and (max-width: 375px) {
    padding: 20px;
  }
`;

const bannerContainer = css`
  width: 100%;
  margin-top: 80px;
  display: flex;
  position: relative;

  @media only screen and (max-width: 375px) {
    margin-top: 0px;
    width: 335px;
    height: 300px;
    border-radius: 12px;
    overflow: hidden;
  }

  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.4) 30%,
    rgba(0, 0, 0, 0) 100%
  );

  .banner-content {
    position: absolute;
    top: 80px;
    left: 80px;

    @media only screen and (max-width: 375px) {
      top: 94px;
      left: 20px;
    }

    .banner-content-row {
      font-family: Pretendard;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.012em;
      text-align: left;
      color: #ffffff;

      @media only screen and (max-width: 375px) {
        font-size: 20px;
      }

      margin-bottom: 17px;
    }

    .banner-content-sub {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 400;
      line-height: 22.4px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #ffffff;

      @media only screen and (max-width: 375px) {
        font-size: 13px;
      }
    }
  }
`;

const companiesContainer = css`
  display: flex;
  flex-flow: row;
  gap: 40px;
  flex-wrap: wrap;
  margin: 80px 0px 160px 0px;

  @media only screen and (max-width: 375px) {
    gap: 20px;
  }

  .companies-card {
    width: 580px;
    height: 290px;
    border-bottom: 1px solid #e5e5e5;
    padding: 40px;
    display: inline-flex;
    flex-flow: column;
    gap: 20px;

    @media only screen and (max-width: 375px) {
      padding: 20px;
      width: 335px;
      height: 203px;
      gap: 12px;
    }

    .logo-area {
      text-align: start;

      @media only screen and (max-width: 375px) {
        svg {
          height: 20px;
          max-width: 158px;
        }
      }
    }
    .intro-text {
      font-family: Pretendard;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
      color: #1e1e1e;
      height: 28px;

      @media only screen and (max-width: 375px) {
        font-size: 18px;
        line-height: 25.2px;
        height: unset;
      }
    }
    .sub-text {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 400;
      line-height: 25.6px;
      letter-spacing: -0.004em;
      text-align: left;
      color: #4b5563;
      height: 52px;

      @media only screen and (max-width: 375px) {
        font-size: 14px;
        line-height: 21px;
        height: unset;
      }
    }

    .btn-area {
      .card-button {
        width: 40px;
        height: 40px;
        padding: 8px 0px 0px 0px;
        border-radius: 100px;
        background: #eeeeee;
        float: right;
        cursor: pointer;
      }
    }
  }
`;

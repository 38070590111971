import React from "react";
import { css } from "@emotion/react";
import { Route, Routes } from "react-router-dom";
import FocusPage from "./focus";
import SolomonPage from "./solomon";
import ProjectPage from "./project";
import FortePage from "./forte";

const Product = () => {
  return (
    <>
      <div
        css={css`
          width: inherit;
          display: flex;
          background: white;
          flex-flow: column;
          margin-top: -111px;

          @media only screen and (max-width: 375px) {
            margin-top: 0px;
          }
        `}
      >
        <Routes>
          <Route path="/focus" Component={FocusPage} />
          <Route path="/solomon" Component={SolomonPage} />
          <Route path="/forte" Component={FortePage} />
          <Route path="/project" Component={ProjectPage} />
        </Routes>
      </div>
    </>
  );
};

export default Product;

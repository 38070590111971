import React from "react";
import { css } from "@emotion/react";
import { useLocation } from "react-router-dom";
import Banner from "../../resources/Product/project/project_banner.png";
import { ReactComponent as ProjectLogo } from "../../resources/Product/project/project_logo.svg";
import { ReactComponent as MetionImg } from "../../resources/Product/forte/img-mention.svg";

import SwiperImg1 from "../../resources/Product/project/project_swiper1_img.png";
import SwiperImg2 from "../../resources/Product/project/project_swiper2_img.png";
import SwiperImg3 from "../../resources/Product/project/project_swiper3_img.png";
import SwiperImg4 from "../../resources/Product/project/project_swiper4_img.png";

import SwiperMobileImg1 from "../../resources/Product/project/project_swiper1_mobile_img.png";
import SwiperMobileImg2 from "../../resources/Product/project/project_swiper2_mobile_img.png";
import SwiperMobileImg3 from "../../resources/Product/project/project_swiper3_mobile_img.png";
import SwiperMobileImg4 from "../../resources/Product/project/project_swiper4_mobile_img.png";

import { ReactComponent as ProjectMgmtImg } from "../../resources/Product/project/project_mgmt_img.svg";
import { ReactComponent as ProjectMgmtMobileImg } from "../../resources/Product/project/project_mgmt_mobile_img.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import { ArrowPolygon } from "./vector";
import { ReactComponent as CustomizeTOolImg } from "../../resources/Product/project/customizeTool_img.svg";
import { ReactComponent as CustomizeTOolMobileImg } from "../../resources/Product/project/customizeTool_mobile_img.svg";

import ProjectDetailImg from "../../resources/Product/project/project_detail_img.png";
import { useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";

const ProjectPage = () => {
  const history = useNavigate();

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div css={wrapperStyle}>
        <div css={imgWrapper}>
          <img src={Banner} />
        </div>
        <div css={textWrapper}>
          <ProjectLogo />
          <div className="head-wrapper">
            효율적인 프로젝트 관리를 위한 <br />
            스마트한 협업 공간
          </div>
          <div className="child-wrapper">
            {window.outerWidth > 375
              ? "SuperApp 기반 다양한 서비스의 연동으로 편리한 프로젝트 관리를 도와줍니다.\r\n조직 관리와 업무 현황 파악 기능을 통합하여 프로젝트 성공을 지원합니다."
              : "SuperApp 기반 다양한 서비스의 연동으로\r\n편리한 프로젝트 관리를 도와줍니다.\r\n조직 관리와 업무 현황 파악 기능을 통합하여\r\n프로젝트 성공을 지원합니다."}
          </div>
        </div>

        <div css={ProjectIntroduction}>
          <div className="intro-title">
            성공을 위한 프로젝트 관리,
            <br />
            FORTE Project와 함께!
          </div>
          <div className="intro-diagram">
            <div className="diagram-wrapper">
              <div className="diagram">
                {window.outerWidth > 375
                  ? "통합 및 연동 기반협업 플레이스"
                  : "통합 및 연동 기반\r\n협업 플레이스"}
              </div>
              <div
                className="diagram"
                css={css`
                  ${window.outerWidth > 375 && `background: rgba(176, 208, 255, 0.1) !important`};
                `}
              >
                {window.outerWidth > 375
                  ? "효율적인\r\n프로젝트 관리"
                  : "효율적인\r\n프로젝트 관리"}
              </div>
              <div className="diagram">산업 구조 맞춤형 커스터마이징</div>
            </div>

            <div className="complain-container">
              <div className="complain-card">
                <MetionImg />
                <div className="card-text-area">
                  <span className="complain-number">01</span>
                  <span className="complain-text">
                    프로젝트 관리기능을
                    <br />한 곳에서 사용하고 싶어요!
                  </span>
                </div>
              </div>
              <div className="complain-card">
                <MetionImg />
                <div className="card-text-area">
                  <span className="complain-number">02</span>
                  <span className="complain-text">
                    우리 업종에 맞는
                    <br />
                    프로젝트 협업툴이 필요해요!
                  </span>
                </div>
              </div>
              <div className="complain-card">
                <MetionImg />
                <div className="card-text-area">
                  <span className="complain-number">03</span>
                  <span className="complain-text">
                    팀원들의 업무 현황 파악을 <br />
                    쉽게 할 수 있는 방법이 없을까요?
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div css={SuperAppContainer}>
          <div className="superApp-text-area">
            <span className="superApp-title">
              {window.outerWidth > 375
                ? " SuperApp 기반 강력한 모듈별 통합·연동"
                : "SuperApp 기반 강력한\r\n모듈별 통합·연동"}
            </span>
            <span className="superApp-sub-title">
              {window.outerWidth > 375 ? (
                <>
                  SuperApp 기반으로 다양한 모듈과 연동이 가능하며, 하나의 플레이스에서 모든 업무를
                  통합적으로 관리할 수 있습니다.
                  <br />
                  이를 통해 실무에 필요한 다양한 도구들을 통합된 공간에서 손쉽게 이용할 수 있습니다.
                </>
              ) : (
                "SuperApp 기반으로 다양한 모듈과 연동이 가능하며,\r\n하나의 플레이스에서 모든 업무를 통합적으로\r\n관리할 수 있습니다.\r\n이를 통해 실무에 필요한 다양한 도구들을 통합된 공간에서\r\n손쉽게 이용할 수 있습니다."
              )}
            </span>
          </div>
          <div className="superApp-swiper-container">
            <Swiper
              slidesPerView={4}
              spaceBetween={24}
              loop={true}
              autoplay={{
                delay: 0,
                disableOnInteraction: true,
              }}
              speed={5500}
              modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
              css={css`
                .swiper-container {
                  height: 100% !important;
                  max-height: 1920px !important;

                  min-height: 0 !important;
                  min-width: 0 !important;
                  max-width: 1920px !important;
                  width: 100% !important;
                  overflow: hidden !important;
                }

                .swiper-wrapper {
                  transition-timing-function: linear;
                  align-items: center !important;
                  max-height: 100% !important;
                  height: 100% !important;
                  display: flex !important;
                }
                .swiper-slide {
                  width: fit-content !important;
                  flex-shrink: 0 !important;
                  display: block !important;
                  height: 100% !important;
                  max-height: 100% !important;
                }
              `}
            >
              <SwiperSlide>
                <img src={window.outerWidth > 375 ? SwiperImg1 : SwiperMobileImg1} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={window.outerWidth > 375 ? SwiperImg2 : SwiperMobileImg2} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={window.outerWidth > 375 ? SwiperImg3 : SwiperMobileImg3} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={window.outerWidth > 375 ? SwiperImg4 : SwiperMobileImg4} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={window.outerWidth > 375 ? SwiperImg1 : SwiperMobileImg1} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={window.outerWidth > 375 ? SwiperImg2 : SwiperMobileImg2} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={window.outerWidth > 375 ? SwiperImg3 : SwiperMobileImg3} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={window.outerWidth > 375 ? SwiperImg4 : SwiperMobileImg4} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={window.outerWidth > 375 ? SwiperImg1 : SwiperMobileImg1} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={window.outerWidth > 375 ? SwiperImg2 : SwiperMobileImg2} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={window.outerWidth > 375 ? SwiperImg3 : SwiperMobileImg3} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={window.outerWidth > 375 ? SwiperImg4 : SwiperMobileImg4} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <div css={ProjectMngmtContainer}>
          <div className="projectMgmt-text-area">
            <span className="projectMgmt-title">
              {window.outerWidth > 375
                ? "효율적인 업무 분담 및 프로젝트 관리"
                : "효율적인 업무 분담 및\r\n프로젝트 관리"}
            </span>
            <span className="projectMgmt-sub-title">
              {window.outerWidth > 375 ? (
                <>
                  실시간 대시보드를 통해 프로젝트 진척률을 체계적으로 확인 할 수 있습니다.
                  <br />
                  담당자의 작업량을 확인 후 적절하게 업무를 분장하여 조직과 업무의 동시 관리가
                  가능합니다.
                  <br />
                  또한 체계적인 일정 관리를 위해 다양한 유형의 대시보드를 제공하여 프로젝트의 일정과
                  진행 상황을 빠르게 확인 할 수 있습니다.
                </>
              ) : (
                <>
                  실시간 대시보드를 통해 프로젝트 진척률을 체계적으로
                  <br />
                  확인 할 수 있습니다. 담당자의 작업량을 확인 후 적절하게
                  <br />
                  업무를 분장하여 조직과 업무의 동시 관리가 가능합니다.
                  <br />
                  또한 체계적인 일정 관리를 위해 다양한 유형의 대시보드를
                  <br />
                  제공하여 프로젝트의 일정과 진행 상황을 빠르게 확인 할 수 있습니다.
                </>
              )}
            </span>
          </div>
          {window.outerWidth > 375 ? <ProjectMgmtImg /> : <ProjectMgmtMobileImg />}
        </div>

        <div css={CusomizeToolContainer}>
          <div className="customizeTool-text-area">
            <span className="customizeTool-title">산업 맞춤형 커스터마이징 협업툴</span>
            <span className="customizeTool-sub-title">
              {window.outerWidth > 375 ? (
                <>
                  산업 환경에 상관없이 산업 구조에 맞춰 커스터마이징이 가능하며, 산업별 특성을
                  고려한 프로젝트 협업 기능을 제공합니다.
                  <br />
                  APP 연동 기반 APP 내 적재된 기업의 산업 특화데이터를 협업툴 안에서 빠르게 찾을 수
                  있습니다.
                </>
              ) : (
                <>
                  산업 환경에 상관없이 산업 구조에 맞춰 커스터마이징이 가능하며, 산업별 특성을
                  고려한 프로젝트 협업 기능을 제공합니다. APP 연동 기반 APP 내 적재된 기업의 산업
                  특화데이터를 협업툴 안에서 빠르게 찾을 수 있습니다.
                </>
              )}
            </span>
          </div>

          <div className="customizeTool-img-wrapper">
            <div className="customizeTool-industry-wrapper">
              <div className="customizeTool-industry-chip">건설업</div>
              <div className="customizeTool-industry-chip">IT,SW</div>
              <div className="customizeTool-industry-chip">제조업</div>
              <div className="customizeTool-industry-chip">유통</div>
            </div>
            <ArrowPolygon />
            {window.outerWidth > 375 ? <CustomizeTOolImg /> : <CustomizeTOolMobileImg />}
          </div>
        </div>

        <div css={DetailBanner}>
          <img src={ProjectDetailImg} />
          <div className="text-area">
            <span className="title">
              성공을 위한 프로젝트 관리,
              <br />
              <ProjectLogo
                css={css`
                  width: 292px;
                  height: 24px;

                  @media only screen and (max-width: 375px) {
                    width: 170px;
                    height: 14px;
                  }
                `}
              />{" "}
              와 함께!
            </span>
            <div
              className="detail-btn"
              onClick={() => {
                history("/contact");
              }}
            >
              FORTE Project 시작하기
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectPage;

const wrapperStyle = css`
  width: inherit;
`;

const imgWrapper = css`
  position: relative;
  width: 100%;
  height: 780px;
  overflow: hidden;

  @media only screen and (max-width: 375px) {
    height: 488px;
  }

  .img-center {
    text-align: center;
    position: absolute;
    top: 0;
    right: -200%;
    bottom: 0;
    left: -200%;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const textWrapper = css`
  position: absolute;
  left: 360px;
  top: 27.82%;

  display: inline-flex;
  flex-flow: column;

  text-align: start;

  @media only screen and (max-width: 375px) {
    left: 20px;
    top: 123px;
  }

  .head-wrapper {
    color: white;
    margin-top: 60px;

    color: #fff;
    font-family: Pretendard;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .child-wrapper {
    color: white;
    margin-top: 32px;

    color: var(--Text-Assistive, #d7d7d7);
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;

    @media only screen and (max-width: 375px) {
      font-size: 1rem;
    }
  }
`;

const ProjectIntroduction = css`
  display: flex;
  padding: 150px 360px 200px 360px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
  align-self: stretch;
  background: #f3f6f8;
  position: relative;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px 128px 20px;
  }

  .intro-title {
    color: #1e1e1e;
    font-family: Pretendard;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-align: start;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .intro-diagram {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    .diagram-wrapper {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .diagram {
        display: inline-flex;
        width: 280px;
        height: 280px;
        padding: 106px 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 999px;
        border: 2px solid #1c64f2;

        background: rgba(28, 100, 242, 0.1);

        mix-blend-mode: multiply;

        color: var(--Primary-Normal, #1c64f2);
        text-align: center;

        font-family: Pretendard;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        margin: 0 -12px;

        @media only screen and (max-width: 375px) {
          width: 160px;
          height: 160px;
          padding: 55px 16px;
          font-size: 1.125rem;
          margin: -24px -12px;
          border: 1px solid #1c64f2;
        }
      }
    }
  }
  .complain-container {
    display: flex;
    //width: 100vw;
    width: 1920px;
    height: 338px;
    padding: 72px 360px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-flow: row;
    position: absolute;
    bottom: -170px;
    z-index: 1;

    @media only screen and (max-width: 375px) {
      padding: 72px 20px;
      flex-flow: column;
      bottom: -410px;
      height: fit-content;
    }

    .complain-card {
      position: relative;
      display: flex;
      padding: 24px 24px 24px 96px;
      align-items: center;
      gap: 24px;
      flex: 1 0 0;
      align-self: stretch;

      border-radius: 20px;
      background: #fff;

      box-shadow: 0px 4px 50px 0px rgba(50, 99, 194, 0.1);
      width: 100%;
      height: 100%;

      @media only screen and (max-width: 375px) {
        padding: 12px 16px 12px 96px;
        gap: 16px;
      }

      svg {
        position: absolute;
        top: 0;
        left: 12px;

        @media only screen and (max-width: 375px) {
          width: 64px;
          height: 58.776px;
        }
      }

      .card-text-area {
        display: flex;
        flex-flow: column;
        padding: 12px 0px;
        gap: 10px;

        .complain-number {
          color: #1c64f2;
          font-family: Pretendard;
          font-size: 2rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          text-align: start;

          @media only screen and (max-width: 375px) {
            font-size: 1.125rem;
          }
        }

        .complain-text {
          align-self: stretch;
          color: #aaa;

          font-family: Pretendard;
          font-size: 1rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          text-align: start;

          @media only screen and (max-width: 375px) {
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 150%;
          }
        }
      }
    }
  }
`;

const SuperAppContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  align-self: stretch;
  background: #fff;

  .superApp-text-area {
    display: flex;
    flex-flow: column;
    gap: 16px;
    width: 100%;
    padding: 320px 360px 0px 360px;

    @media only screen and (max-width: 375px) {
      padding: 415px 20px 48px 20px;
    }

    .superApp-title {
      color: #1e1e1e;

      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 1.5rem;
      }
    }
    .superApp-sub-title {
      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
        line-height: 150%;
      }
    }
  }

  .superApp-swiper-container {
    width: 100%;
    height: 480px;
    align-items: end;
    display: flex;
    background: #eaeff4;
    min-width: 0;

    @media only screen and (max-width: 375px) {
      height: 200px;
    }
  }
`;

const ProjectMngmtContainer = css`
  display: flex;
  padding: 150px 360px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  align-self: stretch;
  background: #f9fafb;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
  }

  .projectMgmt-text-area {
    display: flex;
    flex-flow: column;
    gap: 16px;
    width: 100%;

    .projectMgmt-title {
      color: #1e1e1e;

      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 1.5rem;
      }
    }
    .projectMgmt-sub-title {
      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
        line-height: 150%;
      }
    }
  }
`;

const CusomizeToolContainer = css`
  display: flex;
  padding: 150px 360px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  align-self: stretch;
  background: #fff;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
    gap: 32px;
  }

  .customizeTool-text-area {
    display: flex;
    flex-flow: column;
    gap: 16px;
    width: 100%;

    .customizeTool-title {
      color: #1e1e1e;

      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 1.5rem;
      }
    }
    .customizeTool-sub-title {
      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
        line-height: 150%;
      }
    }
  }

  .customizeTool-img-wrapper {
    display: flex;
    width: 100%;
    flex-flow: column;
    gap: 12px;
    align-items: center;

    .customizeTool-industry-wrapper {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      flex-flow: row;
      gap: 24px;

      border-radius: 20px;
      border: 1px dashed #1c64f2;

      background: #e8f0f7;

      @media only screen and (max-width: 375px) {
        flex-wrap: wrap;
        padding: 12px;
        gap: 12px;
      }

      .customizeTool-industry-chip {
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;

        @media only screen and (max-width: 375px) {
          width: 147.5px;
          height: 37px;
          font-size: 0.875rem;
          padding: 8px 16px;
          flex: unset;
        }

        border-radius: 999px;
        background: #1c64f2;

        color: #fff;

        text-align: center;
        font-family: Pretendard;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
    }
  }
`;

const DetailBanner = css`
  position: relative;
  @media only screen and (max-width: 375px) {
    height: 282px;
    overflow: hidden;
  }
  .text-area {
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;

    gap: 48px;

    @media only screen and (max-width: 375px) {
      gap: 24px;
      width: 100%;
    }

    .title {
      color: #fff;

      text-align: center;
      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      @media only screen and (max-width: 375px) {
        font-size: 1.125rem;
      }
    }

    .detail-btn {
      display: flex;
      width: 300px;
      height: 48px;
      min-width: 131px;
      padding: 10px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      border-radius: 4px;
      background: #fff;

      color: var(--Text-Normal, #1e1e1e);
      text-align: center;
      font-family: Pretendard;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      cursor: pointer;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
        padding: 8px 24px;
        width: 194px;
        font-weight: 600;
      }
    }
  }
`;

import React from "react";
import { css } from "@emotion/react";
import { useLocation } from "react-router-dom";
import Banner from "../../resources/Product/solomon/solomon_banner.png";
import { ReactComponent as BiSolomonLogo } from "../../resources/Product/solomon/bi_solomon_logo.svg";

import { ReactComponent as DataFrameIcon } from "../../resources/Product/solomon/dataframe_icon.svg";
import { ReactComponent as AxStudioIcon } from "../../resources/Product/solomon/ax_sutdio_icon.svg";
import { ReactComponent as SolomonStudioIcon } from "../../resources/Product/solomon/solomon_sutdio_icon.svg";

import { ReactComponent as PeopleImg } from "../../resources/Product/solomon/people_img.svg";
import { ReactComponent as People2Img } from "../../resources/Product/solomon/people2_img.svg";
import { ReactComponent as People3Img } from "../../resources/Product/solomon/people3_img.svg";

import { ReactComponent as AxStudioImg } from "../../resources/Product/solomon/ax-studio-img.svg";
import { ReactComponent as AxStudioMobileImg } from "../../resources/Product/solomon/ax-studio_mobile_img.svg";
import { ArrowPolygon } from "./vector";
import SolomonDetailImg from "../../resources/Product/solomon/solomon_detail_img.png";

import DataFrame from "./dataFrame";

import { ReactComponent as SwiperImg1 } from "../../resources/Product/solomon/solomon_studio_swiper1.svg";
import { ReactComponent as SwiperImg2 } from "../../resources/Product/solomon/solomon_studio_swiper2.svg";

import SwiperMobileImg1 from "../../resources/Product/solomon/solomon_studio_swiper1_mobile.png";
import SwiperMobileImg2 from "../../resources/Product/solomon/solomon_studio_swiper2_mobile.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import { useNavigate } from "react-router-dom";

const SolomonPage = () => {
  const history = useNavigate();
  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  React.useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <div css={wrapperStyle}>
        <div css={imgWrapper}>
          <div className="img-center">
            <img src={Banner} />
          </div>
          <div css={textWrapper}>
            <BiSolomonLogo />
            <div className="head-wrapper">
              {window.outerWidth > 375
                ? "기업 운영의 최선의\r\n방법을 제시하는 비즈니스 파트너"
                : "기업 운영의 최선의 방법을 제시하는\r\n비즈니스 파트너"}
            </div>
            <div className="child-wrapper">
              {window.outerWidth > 375
                ? "기업 내 데이터를 통합/분석하여 사용자에 니즈에 따라 데이터를 시각화 합니다.\r\nAI분석 기반의 최적의 비즈니스 Path 제안을 경험해 보세요!"
                : "기업 내 데이터를 통합/분석하여 사용자에 니즈에\r\n따라 데이터를 시각화 합니다. AI분석 기반의 최적의\r\n비즈니스 Path 제안을 경험해 보세요!"}
            </div>
          </div>
        </div>

        <div css={SolomonIntroduction}>
          <div className="intro-title">
            기업의 의사결정을 위한
            <br />
            가장 올바른 답을 제시하는 AI 통합 B2B 서비스
          </div>
          <div className="intro-diagram">
            <div className="intro-card-wrapper">
              <div
                className="intro-card"
                css={css`
                  background: linear-gradient(180deg, #235fd2 0%, #250877 75.49%);
                  margin-top: 36px;

                  @media only screen and (max-width: 375px) {
                    margin-top: 0px;
                  }
                `}
              >
                <div className="intro-card-title">DataFrame</div>
                <div className="intro-card-sub">가상화 기반 데이터 통합</div>
                <div className="intro-card-img">
                  <DataFrameIcon />
                </div>
              </div>
              <div
                className="intro-card"
                css={css`
                  background: linear-gradient(180deg, #2267f3 0%, #0f3173 100%);
                `}
              >
                <div className="intro-card-title">AX Studio</div>
                <div className="intro-card-sub">
                  기업의 환경에 관계없는
                  <br />
                  AI 개발 전문 엔진
                </div>
                <div className="intro-card-img">
                  <AxStudioIcon />
                </div>
              </div>
              <div
                className="intro-card"
                css={css`
                  background: linear-gradient(180deg, #00c0da 0%, #0a1f91 102.19%);
                  margin-top: 36px;

                  @media only screen and (max-width: 375px) {
                    margin-top: 0px;
                  }
                `}
              >
                <div className="intro-card-title">
                  SOLOMON
                  <br />
                  Studio
                </div>
                <div className="intro-card-sub">
                  데이터 시각화를 통한
                  <br />
                  최적의 의사결정 지원
                </div>
                <div className="intro-card-img">
                  <SolomonStudioIcon />
                </div>
              </div>
            </div>

            <div className="complain-container">
              <div className="complain-card">
                <div className="img-area">
                  <PeopleImg />
                </div>
                <div className="card-text-area">
                  <span className="complain-number">01</span>
                  <span className="complain-text">
                    {window.outerWidth > 375
                      ? "  물리적으로 관리가 힘든\r\n데이터, 가상화해서\r\n통합 관리 하고싶어요!"
                      : "물리적으로 관리가 힘든 데이터,\r\n가상화해서 통합 관리 하고싶어요!"}
                  </span>
                </div>
              </div>
              <div className="complain-card">
                <div className="img-area">
                  <People2Img />
                </div>
                <div className="card-text-area">
                  <span className="complain-number">02</span>
                  <span className="complain-text">
                    우리 회사에서도 AI를 <br />
                    쉽게 써보고 싶어요!
                  </span>
                </div>
              </div>
              <div className="complain-card">
                <div className="img-area">
                  <People3Img />
                </div>
                <div className="card-text-area">
                  <span className="complain-number">03</span>
                  <span className="complain-text">
                    {window.outerWidth > 375
                      ? "데이터 기반의사결정을 하고\r\n싶은데, 전문 인력 없이도\r\n가능할까요?"
                      : "데이터 기반의사결정을 하고 싶은데,\r\n전문 인력 없이도 가능할까요?"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div css={DataFrameContainer}>
          <div className="dataFrame-text-area">
            <span className="dataFrame-title">DataFrame</span>
            <span className="dataFrame-sub-title">
              {window.outerWidth > 375 ? (
                <>
                  기업 내 분산된 데이터를 가상화 기술 기반 자동 통합하여,
                  <br />
                  AI연계가 가능한 데이터 분석을 지원 해줍니다.
                </>
              ) : (
                <>
                  기업 내 분산된 데이터를 가상화 기술 기반 자동 통합하여, AI연계가 가능한 데이터
                  분석을 지원 해줍니다.
                </>
              )}
            </span>
          </div>
          <DataFrame />
        </div>

        <div css={AxStudioContainer}>
          <div className="axStudio-text-area">
            <span className="axStudio-title">AX Studio</span>
            <span className="axStudio-sub-title">
              {window.outerWidth > 375 ? (
                <>
                  기업의 규모와 산업에 관계없이
                  <br />
                  비즈니스 특화된 기업용 AI 개발 전문 엔진을 제공합니다.
                </>
              ) : (
                <>
                  기업의 규모와 산업에 관계없이 비즈니스 특화된 기업용 AI 개발 전문 엔진을
                  제공합니다.
                </>
              )}
            </span>
          </div>

          <div className="axStudio-img-area">
            {window.outerWidth > 375 ? <AxStudioImg /> : <AxStudioMobileImg />}
            <ArrowPolygon />
            <div className="business-model-wrapper">
              <div className="btn">AI Business Model</div>
              <span className="detail">기업의 환경에 관계없이 비즈니스 이슈 해결</span>
            </div>
          </div>
        </div>

        <div css={SolomonStudioContainer}>
          <div className="solomonStudio-text-area">
            <span className="solomonStudio-title">SOLOMON Studio</span>
            <span className="solomonStudio-sub-title">
              {window.outerWidth > 375 ? (
                <>
                  비즈니스 인사이트 기반 초 개인화 분석 대시보드 제공 및 구성을 통해 기업의
                  의사결정을 지원합니다.
                </>
              ) : (
                <>
                  비즈니스 인사이트 기반 초 개인화 분석 대시보드 제공 및 구성을 통해 기업의
                  의사결정을 지원합니다.
                </>
              )}
            </span>
          </div>
          <div className="swiper-wrapper">
            <Swiper
              slidesPerView={1}
              spaceBetween={50}
              navigation={window.outerWidth > 375 ? true : false}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              css={css`
                --swiper-navigation-size: 40px;

                .swiper-button-next,
                .swiper-button-prev {
                  width: 40px;
                  height: 40px;
                  background: #f2f2f2;
                  border-radius: 999px;

                  &:after {
                    font-size: 20px;
                    color: #5d5d5d;
                  }
                }
                .swiper-button-next {
                  right: 279px;
                  &:after {
                    position: relative;
                    left: 2px;
                  }
                }
                .swiper-button-prev {
                  left: 280px;
                  &:after {
                    position: relative;
                    left: -1px;
                  }
                }
              `}
            >
              <SwiperSlide>
                <div className="swiper-container">
                  <div className="swiper-left">
                    <span className="title">SOLOMON Studio</span>
                    <span className="sub">분석 결과를 기반으로 인사이트 시각화</span>
                  </div>
                  {window.outerWidth > 375 ? <SwiperImg1 /> : <img src={SwiperMobileImg1} />}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-container">
                  <div className="swiper-left">
                    <span className="title">Dashboard</span>
                    <span className="sub">업무 맞춤형 개인화 Dashboard 구성</span>
                  </div>
                  {window.outerWidth > 375 ? <SwiperImg2 /> : <img src={SwiperMobileImg2} />}
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="target-wrapper">
            <div className="target-chip">의사결정권자</div>
            <div className="target-chip">재무 실무자</div>
            <div className="target-chip">영업 실무자</div>
            <div className="target-chip">인사 실무자</div>
          </div>
        </div>

        <div css={DetailBanner}>
          <img src={SolomonDetailImg} />
          <div className="text-area">
            <span className="title">
              데이터 중심의 기업 맞춤형 AI를 통해
              <br />
              새로운 비즈니스 인사이트를 만나보세요
            </span>
            <div
              className="detail-btn"
              onClick={() => {
                history("/contact");
              }}
            >
              Solomon 시작하기
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SolomonPage;

const wrapperStyle = css`
  width: inherit;
`;

const imgWrapper = css`
  position: relative;
  width: 100%;
  height: 780px;
  overflow: hidden;

  @media only screen and (max-width: 375px) {
    height: 480px;
  }

  .img-center {
    text-align: center;
    position: absolute;
    top: 0;
    right: -200%;
    bottom: 0;
    left: -200%;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const textWrapper = css`
  position: absolute;
  left: 360px;
  top: 27.82%;

  display: inline-flex;
  flex-flow: column;

  text-align: start;

  @media only screen and (max-width: 375px) {
    left: 20px;
    top: 123px;
  }

  .head-wrapper {
    color: white;
    margin-top: 60px;

    color: #fff;
    font-family: Pretendard;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    white-space: pre-line;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .child-wrapper {
    color: white;
    margin-top: 32px;
    line-height: 170%;
    white-space: pre-line;
    color: #d7d7d7;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;

    @media only screen and (max-width: 375px) {
      font-size: 1rem;
    }
  }
`;

const SolomonIntroduction = css`
  display: flex;
  padding: 150px 360px 200px 360px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
  align-self: stretch;
  background: #f3f6f8;
  position: relative;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px 128px 20px;
    gap: 32px;
  }

  .intro-title {
    color: #1e1e1e;
    font-family: Pretendard;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-align: start;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .intro-diagram {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    .intro-card-wrapper {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 24px;

      @media only screen and (max-width: 375px) {
        gap: 20px;
      }

      .intro-card {
        display: inline-flex;
        width: 384px;
        height: 480px;
        padding: 48px 24px;
        flex-direction: column;
        align-items: start;
        gap: 10px;
        position: relative;

        border-radius: 20px;

        mix-blend-mode: multiply;

        @media only screen and (max-width: 375px) {
          width: 100%;
          height: 180px;
        }

        .intro-card-title {
          color: #fff;
          font-family: Pretendard;
          font-size: 2rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          text-align: start;

          @media only screen and (max-width: 375px) {
            font-size: 1.125rem;
          }
        }

        .intro-card-sub {
          color: #fff;
          font-family: Pretendard;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: -0.16px;
          text-align: start;

          @media only screen and (max-width: 375px) {
            font-size: 0.875rem;
          }
        }

        .intro-card-img {
          position: absolute;
          bottom: 0;
          right: 0;

          @media only screen and (max-width: 375px) {
            svg {
              width: 160px;
              height: 150px;
            }
          }
        }
      }
    }
  }
  .complain-container {
    display: flex;
    //width: 100vw;
    width: 1920px;
    height: 338px;
    padding: 72px 360px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-flow: row;
    position: absolute;
    bottom: -170px;
    z-index: 1;

    @media only screen and (max-width: 375px) {
      padding: 72px 20px;
      flex-flow: column;
      bottom: -450px;
      height: fit-content;
    }

    .complain-card {
      position: relative;
      display: flex;
      padding: 24px;
      align-items: center;
      gap: 24px;
      flex: 1 0 0;
      align-self: stretch;

      border-radius: 20px;
      background: #fff;

      box-shadow: 0px 4px 50px 0px rgba(50, 99, 194, 0.1);
      width: 100%;
      height: 100%;

      @media only screen and (max-width: 375px) {
        padding: 12px 16px 12px 16px;
        gap: 16px;

        .img-area {
          svg {
            width: 64px;
            height: 64px;
          }
        }
      }

      .card-text-area {
        display: flex;
        flex-flow: column;
        padding: 12px 0px;
        gap: 10px;
        padding: 12px 24px;

        border-left: 1px solid #dddddd;
        @media only screen and (max-width: 375px) {
          padding: 12px 0px 12px 14px;
        }

        .complain-number {
          color: #1c64f2;
          font-family: Pretendard;
          font-size: 2rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          text-align: start;

          @media only screen and (max-width: 375px) {
            font-size: 1.125rem;
          }
        }

        .complain-text {
          align-self: stretch;
          color: #aaa;

          font-family: Pretendard;
          font-size: 1rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          text-align: start;
          white-space: pre-line;

          @media only screen and (max-width: 375px) {
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 150%;
          }
        }
      }
    }
  }
`;

const DataFrameContainer = css`
  display: flex;
  padding: 320px 360px 150px 360px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
  align-self: stretch;
  background: #fff;

  @media only screen and (max-width: 375px) {
    padding: 463px 20px 48px 20px;
  }

  .dataFrame-text-area {
    display: flex;
    flex-flow: column;
    gap: 16px;
    width: 100%;

    .dataFrame-title {
      color: #1e1e1e;

      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 1.5rem;
      }
    }
    .dataFrame-sub-title {
      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
        line-height: 150%;
      }
    }
  }
`;

const AxStudioContainer = css`
  display: flex;
  padding: 150px 360px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  align-self: stretch;
  background: #f9fafb;

  @media only screen and (max-width: 375px) {
    padding: 48px 20px;
    gap: 32px;
  }

  .axStudio-text-area {
    display: flex;
    flex-flow: column;
    gap: 16px;
    width: 100%;

    .axStudio-title {
      color: #1e1e1e;

      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 1.5rem;
      }
    }
    .axStudio-sub-title {
      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
        line-height: 150%;
      }
    }
  }

  .axStudio-img-area {
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 34px;
    align-self: stretch;
    border-radius: 20px;
    background: #f1f4f8;

    @media only screen and (max-width: 375px) {
      gap: 20px;
      padding: 16px;
    }

    .business-model-wrapper {
      display: flex;
      flex-flow: column;
      gap: 24px;

      .btn {
        display: flex;
        padding: 16px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 16px;
        background: #264789;
        width: 586px;

        color: #fff;

        text-align: center;
        font-family: Pretendard;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;

        @media only screen and (max-width: 375px) {
          width: 100%;
          font-size: 1.25rem;
          padding: 8px 24px;
          border-radius: 8px;
        }
      }
      .detail {
        color: #1e1e1e;

        text-align: center;
        font-family: Pretendard;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;

        @media only screen and (max-width: 375px) {
          font-size: 0.875rem;
        }
      }
    }
  }
`;

const SolomonStudioContainer = css`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  align-self: stretch;
  background: #fff;

  @media only screen and (max-width: 375px) {
    gap: 32px;
  }

  .solomonStudio-text-area {
    display: flex;
    flex-flow: column;
    gap: 16px;
    width: 100%;
    padding: 150px 360px 0px 360px;

    @media only screen and (max-width: 375px) {
      padding: 48px 20px 0px 20px;
    }

    .solomonStudio-title {
      color: #1e1e1e;

      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 1.5rem;
      }
    }
    .solomonStudio-sub-title {
      color: #1e1e1e;
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-align: start;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
        line-height: 150%;
      }
    }
  }

  .swiper-wrapper {
    width: 100%;
    max-width: 1920px;
    height: 428px;
    @media only screen and (max-width: 375px) {
      max-width: 375px;
      height: 266px;
    }

    .swiper-container {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
      gap: 24px;

      @media only screen and (max-width: 375px) {
        flex-flow: column-reverse;
      }

      .swiper-left {
        display: flex;
        flex-flow: column;
        gap: 16px;

        width: 280px;

        @media only screen and (max-width: 375px) {
          gap: 8px;
          widht: 100%;
        }

        .title {
          color: #1c64f2;
          text-align: center;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          text-align: start;

          @media only screen and (max-width: 375px) {
            font-size: 1.25rem;
            text-align: center;
          }
        }

        .sub {
          color: #aaa;
          font-size: 1rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          text-align: start;

          @media only screen and (max-width: 375px) {
            font-size: 0.875rem;
            text-align: center;
          }
        }
      }
    }
  }

  .target-wrapper {
    display: flex;
    width: 100%;
    gap: 24px;
    flex-flow: row;
    padding: 0px 360px 150px 360px;

    @media only screen and (max-width: 375px) {
      padding: 0px 20px 48px 20px;
      gap: 20px;
      flex-wrap: wrap;
    }

    .target-chip {
      display: flex;
      height: 56px;
      padding: 24px 60px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;

      @media only screen and (max-width: 375px) {
        padding: 12px 16px;
        font-size: 1rem;
        width: 157.5px;
        height: 46px;
        flex: unset;
      }

      border-radius: 999px;
      background: #ebf5ff;

      color: #1c64f2;

      text-align: center;
      font-family: Pretendard;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }
  }
`;

const DetailBanner = css`
  position: relative;
  @media only screen and (max-width: 375px) {
    height: 282px;
    overflow: hidden;
  }
  .text-area {
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;

    gap: 48px;

    @media only screen and (max-width: 375px) {
      gap: 24px;
      width: 100%;
    }

    .title {
      color: #fff;

      text-align: center;
      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      @media only screen and (max-width: 375px) {
        font-size: 1.125rem;
      }
    }

    .detail-btn {
      display: flex;
      width: 300px;
      height: 48px;
      min-width: 131px;
      padding: 10px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      border-radius: 4px;
      background: #fff;

      color: var(--Text-Normal, #1e1e1e);
      text-align: center;
      font-family: Pretendard;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      cursor: pointer;

      @media only screen and (max-width: 375px) {
        font-size: 0.875rem;
        padding: 8px 17px;
        width: 144px;
        font-weight: 600;
      }
    }
  }
`;

import React from "react";
import { css } from "@emotion/react";
import { ReactComponent as HistoryArch } from "../../resources/About/history-arch.svg";
import { ReactComponent as HistoryBanner } from "../../resources/About/history_banner.svg";
import { ReactComponent as HistoryMobileBanner } from "../../resources/About/history_banner_mobile.svg";
import { ReactComponent as BiLogoDark } from "../../resources/bi_logo_dark.svg";
import { ReactComponent as TmaxLogo } from "../../resources/About/tmax_logo.svg";

const History = () => {
  return (
    <div css={wrapperStyle}>
      <div css={bannerStyle}>
        <div className="img_area">
          <HistoryArch />
          <BiLogoDark
            width={311}
            height={40}
            css={css`
              position: absolute;
            `}
          />
        </div>
        <div className="title_area">
          {
            "슈퍼앱 기술을 기반으로 디지털 전환에 필요한\r\n핵심 B2B 서비스를 Full-Stack으로 제공합니다."
          }
        </div>
      </div>

      <div css={contentStyle}>
        <div className="content-title">주요 연혁</div>
        <div className="content-table">
          <div className="table-row">
            <div className="table-date">2024</div>
            <div className="table-content">
              {
                "SuperApp Day 프로그램 주최 (AI 경영의 미래, 이제 현실이 되다. GAIA SuperCompany)\r\n‘성남시 청년고용우수기업’ 선정"
              }
            </div>
          </div>
          <div className="table-row">
            <div className="table-date">2023</div>
            <div className="table-content">{"'티맥스비아이'로 사명 변경"}</div>
          </div>
          <div className="table-row">
            <div className="table-date">2022</div>
            <div className="table-content">
              {
                "'티맥스포커스'로 사명 변경\r\n본사 이전 (분당구 수내동 예미지빌딩)\r\nTmax Super Week 주최"
              }
            </div>
          </div>
          <div className="table-row">
            <div className="table-date">2021</div>
            <div className="table-content">{"스타트업·중소기업 AI 기반 데이터 활용 지원"}</div>
          </div>
          <div className="table-row">
            <div className="table-date">2020</div>
            <div className="table-content">{"티맥스 비아이 설립"}</div>
          </div>
        </div>

        <div css={bannerContainer}>
          {window.outerWidth > 375 ? <HistoryBanner /> : <HistoryMobileBanner />}

          <div className="banner-content">
            <div className="banner-content-row">
              <BiLogoDark /> 는
            </div>
            <div className="banner-content-row">세계적인 기술력의 집합체,</div>
            <div className="banner-content-row">
              <TmaxLogo /> 와 함께합니다.
            </div>
            <div className="banner-content-sub">
              {
                "원천 기술을 기반으로 한 새로운 혁신과 끊임없는 도전\r\n새로운 IT 세상을 만드는 것, 티맥스의 궁극적인 목표입니다."
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;

const wrapperStyle = css`
  width: inherit;
  background: #fff;
  text-align: center;

  @media only screen and (max-width: 375px) {
    margin-top: 0px;
  }
`;

const bannerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    90deg,
    #e0eafb 0%,
    #e6ebfb 30%,
    #efe7f4 50%,
    #e6ebfb 70%,
    #e0f3fb 80%,
    #e0f4fb 100%
  );
  width: 100%;
  height: 380px;
  top: 124px;
  gap: 0px;
  opacity: 0px;
  flex-flow: column;

  @media only screen and (max-width: 375px) {
    height: 240px;
  }

  .img_area {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 311px;
    position: relative;

    @media only screen and (max-width: 375px) {
      svg {
        &:first-of-type {
          width: 100px;
          height: 100px;
        }
        &:nth-child(2) {
          width: 160px;
          height: 20px;
        }
      }
    }
  }

  .title_area {
    color: #4b5563;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.2px;
    letter-spacing: -0.004em;
    text-align: center;
    margin-top: 14px;

    @media only screen and (max-width: 375px) {
      font-size: 13px;
    }
  }
`;

const contentStyle = css`
  width: 100%;
  padding: 80px 360px;

  @media only screen and (max-width: 375px) {
    padding: 40px 20px;
  }

  .content-title {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #1e1e1e;

    @media only screen and (max-width: 375px) {
      font-size: 18px;
    }
  }

  .content-table {
    margin-top: 40px;

    .table-row {
      border-top: 1px solid #e5e5e5;
      display: flex;
      flex-flow: row;
      gap: 59px;
      padding: 40px 0px;

      @media only screen and (max-width: 375px) {
        padding: 20px 0px;
      }

      .table-date {
        font-family: Pretendard;
        font-size: 18px;
        font-weight: 600;
        line-height: 25.2px;
        text-align: left;
        color: #1e1e1e;

        @media only screen and (max-width: 375px) {
          font-size: 16px;
        }
      }

      .table-content {
        font-family: Pretendard;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-align: left;
        color: #4b5563;

        @media only screen and (max-width: 375px) {
          font-size: 16px;
        }
      }
    }
  }
`;

const bannerContainer = css`
  width: 100%;
  margin-top: 80px;
  display: flex;
  position: relative;

  @media only screen and (max-width: 375px) {
    margin-top: 0px;
    width: 335px;
    height: 300px;
    border-radius: 12px;
    overflow: hidden;
  }

  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.4) 30%,
    rgba(0, 0, 0, 0) 100%
  );

  .banner-content {
    position: absolute;
    top: 80px;
    left: 80px;

    @media only screen and (max-width: 375px) {
      top: 94px;
      left: 20px;
    }

    .banner-content-row {
      font-family: Pretendard;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.012em;
      text-align: left;
      color: #ffffff;

      margin-bottom: 17px;

      @media only screen and (max-width: 375px) {
        font-size: 20px;
      }
    }

    .banner-content-sub {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 400;
      line-height: 22.4px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #ffffff;

      @media only screen and (max-width: 375px) {
        font-size: 13px;
      }
    }
  }
`;

import React, { useEffect } from "react";
import { css } from "@emotion/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

import Banner1 from "../../resources/Main/banner_first.png";
import Banner2 from "../../resources/Main/banner_second.png";
import Banner3 from "../../resources/Main/banner_third.png";
import HireBanner from "../../resources/Main/hire_banner.png";

import { ReactComponent as WepkitIcon } from "../../resources/Main/wepkit_icon.svg";
import { ReactComponent as MobileArrowIcon } from "../../resources/Main/mobile_arrow.svg";
import { ReactComponent as ProductBtnIcon } from "../../resources/Main/product_btn_icon.svg";
import { ReactComponent as BiLogoDark } from "../../resources/bi_logo_dark.svg";

import FocusLogo from "../../resources/Home/Focus_blue.png";
import ForteLogo from "../../resources/Home/Forte_blue.png";
import SolomonLogo from "../../resources/Home/Solomon_blue.png";
import ProjectLogo from "../../resources/Home/ForteProject_blue.png";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Home = () => {
  const history = useNavigate();

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div
        css={css`
          width: inherit;
          margin-top: -61px;

          @media only screen and (max-width: 375px) {
            margin-top: 0px;
          }
        `}
      >
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={window.outerWidth > 375 ? 50 : 1500}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={window.outerWidth > 375 ? true : false}
          css={SwiperWrapper}
        >
          <SwiperSlide>
            <div css={SwiperSlideStyle}>
              <img src={Banner1} />
              <div className="banner-title-first">
                <div className="img-wrapper">
                  <BiLogoDark />
                </div>
                <div className="card-wrapper">
                  <div css={BannerCardWrapper}>
                    <span className="card-title">CRM</span>
                    <span className="card-content">통합 세일즈 솔루션</span>
                  </div>
                  <div css={BannerCardWrapper}>
                    <span className="card-title">Business Intelligence</span>
                    <span className="card-content">비즈니스 인사이트</span>
                  </div>
                  <div css={BannerCardWrapper}>
                    <span className="card-title">ERP</span>
                    <span className="card-content">기업 자원 및 업무 관리</span>
                  </div>
                  <div css={BannerCardWrapper}>
                    <span className="card-title">AI</span>
                    <span className="card-content">기업용 AI</span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div css={SwiperSlideStyle}>
              <img src={Banner2} />
              <div className="banner-title-second">
                Full-Stack B2B Solution
                <br />
                TmaxBizAI SuperApp
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div css={SwiperSlideStyle}>
              <img src={Banner3} />
              <div className="banner-title-third">
                {window.outerWidth > 375 ? (
                  <>
                    TmaxBizAI는 SuperApp 기술을 바탕으로
                    <br />
                    기업의 디지털 혁신에 필요한 솔루션을 제공합니다
                  </>
                ) : (
                  <>
                    TmaxBizAI는 SuperApp 기술을
                    <br />
                    바탕으로 기업의 디지털 혁신에
                    <br />
                    필요한 솔루션을 제공합니다
                  </>
                )}
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <div css={ProductSection}>
            <div
              css={css`
                display: flex;
                //width: 62.5vw;
                width: 1200px;
                align-items: flex-start;
                align-content: flex-start;
                gap: 24px;
                flex-wrap: wrap;

                @media only screen and (max-width: 375px) {
                  width: 100%;
                  padding-left: 24px;
                }
              `}
            >
              <div css={ProductContentWrapper}>
                <div className="product_content_area">
                  <div className="logo_warpper">
                    <img
                      src={FocusLogo}
                      css={css`
                        width: 252px;
                        height: 40px;

                        @media only screen and (max-width: 375px) {
                          width: 168px;
                          height: 16px;
                        }
                      `}
                    />
                    <div className="logo_intro">
                      {"CRM & Sales 통합 서비스"}
                      {window.outerWidth <= 375 && (
                        <MobileArrowIcon
                          onClick={() => {
                            history("/product/focus");
                          }}
                        />
                      )}
                    </div>
                    <div
                      css={css`
                        text-align: end;

                        @media only screen and (max-width: 375px) {
                          display: none;
                        }
                      `}
                    >
                      <ProductBtnIcon
                        css={BtnIconStyle}
                        onClick={() => {
                          history("/product/focus");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div css={ProductContentWrapper}>
                <div className="product_content_area">
                  <div className="logo_warpper">
                    <img
                      src={SolomonLogo}
                      css={css`
                        width: 288px;
                        height: 40px;

                        @media only screen and (max-width: 375px) {
                          width: 155px;
                          height: 16px;
                        }
                      `}
                    />
                    <div className="logo_intro">
                      {"데이터분석 서비스"}
                      {window.outerWidth <= 375 && (
                        <MobileArrowIcon
                          onClick={() => {
                            history("/product/solomon");
                          }}
                        />
                      )}
                    </div>
                    <div
                      css={css`
                        text-align: end;
                        @media only screen and (max-width: 375px) {
                          display: none;
                        }
                      `}
                    >
                      <ProductBtnIcon
                        css={BtnIconStyle}
                        onClick={() => {
                          history("/product/solomon");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div css={ProductContentWrapper}>
                <div className="product_content_area">
                  <div className="logo_warpper">
                    <img
                      src={ForteLogo}
                      css={css`
                        width: 234px;
                        height: 38px;

                        @media only screen and (max-width: 375px) {
                          width: 202px;
                          height: 17px;
                        }
                      `}
                    />
                    <div className="logo_intro">
                      {"ERP서비스"}
                      {window.outerWidth <= 375 && (
                        <MobileArrowIcon
                          onClick={() => {
                            history("/product/forte");
                          }}
                        />
                      )}
                    </div>
                    <div
                      css={css`
                        text-align: end;

                        @media only screen and (max-width: 375px) {
                          display: none;
                        }
                      `}
                    >
                      <ProductBtnIcon
                        css={BtnIconStyle}
                        onClick={() => {
                          history("/product/forte");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div css={ProductContentWrapper}>
                <div className="product_content_area">
                  <div className="logo_warpper">
                    <img
                      src={ProjectLogo}
                      css={css`
                        width: 405px;
                        height: 50px;

                        @media only screen and (max-width: 375px) {
                          width: 270px;
                          height: 32px;
                        }
                      `}
                    />
                    <div className="logo_intro">
                      {"프로젝트 관리 서비스"}
                      {window.outerWidth <= 375 && (
                        <MobileArrowIcon
                          onClick={() => {
                            history("/product/project");
                          }}
                        />
                      )}
                    </div>
                    <div
                      css={css`
                        text-align: end;
                        @media only screen and (max-width: 375px) {
                          display: none;
                        }
                      `}
                    >
                      <ProductBtnIcon
                        css={BtnIconStyle}
                        onClick={() => {
                          history("/product/project");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div css={ProductBannerWrapper}>
              <div
                css={css`
                  display: flex;
                  width: 1080px;
                  justify-content: space-between;
                  align-items: center;

                  @media only screen and (max-width: 375px) {
                    width: 335px;
                  }
                `}
              >
                <div className="divider">
                  <div className="left_area">
                    <div className="text_area">
                      <span className="title">새로운 도약을 위한 첫 걸음</span>
                      <span className="content">
                        TmaxBizAI 소프트웨어 도입을 고민하고 계신가요? <br />
                        간편한 문의로 시작해보세요!
                      </span>
                    </div>
                    <button
                      onClick={() => {
                        history("/contact");
                      }}
                    >
                      제품 도입 및 문의하기
                    </button>
                  </div>
                  <div className="icon-wrapper">
                    <WepkitIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div css={HireContainer}>
          <div
            css={css`
              //width: 100vw;
              width: 1920px;
              overflow: hidden;

              @media only screen and (max-width: 375px) {
                height: 240px;
              }
            `}
          >
            <img src={HireBanner} />
          </div>
          <div className="text-area">
            <span className="title">WE’RE HIRING</span>
            <span className="sub-title">
              BizAI의 성장 이야기에 함께할 분을 찾고 있습니다.
              <br />
              BizAI와 함께 더 나은 미래를 만들어보세요.
            </span>
            <div
              className="hire-btn"
              onClick={() => {
                window.location.href = "https://tmaxcareers.ninehire.site/";
              }}
            >
              인재 채용 바로가기
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const SwiperWrapper = css`
  height: 780px;
  //width: 100vw;
  width: 1920px;

  --swiper-navigation-color: #fff;
  --swiper-navigation-size: 30px;
  --swiper-navigation-sides-offset: 32px;

  --swiper-pagination-bullet-width: 48px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-border-radius: 0px;

  .swiper-pagination {
    bottom: 64px;
  }

  .swiper-pagination-bullet {
    background: #fff;
    opacity: unset;
  }
  .swiper-pagination-bullet-active {
    background: #1c64f2;
  }
`;

const SwiperSlideStyle = css`
  height: 100%;
  //width: 100vw;
  width: 1920px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .banner-title-first {
    position: absolute;

    .img-wrapper {
      svg {
        width: 278px;
        height: 50px;
      }

      @media only screen and (max-width: 375px) {
        svg {
          width: 134px;
          height: 24px;
        }
      }
    }
  }

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    width: 824px;
    margin-top: 63px;

    @media only screen and (max-width: 375px) {
      gap: 12px;
      flex-flow: column;
      align-items: center;
      margin-top: 26px;
    }
  }

  .banner-title-second {
    position: absolute;
    color: #fff;

    text-align: center;
    font-family: Pretendard;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  .banner-title-third {
    position: absolute;
    color: #fff;
    top: 200px;

    text-align: center;
    font-family: Pretendard;
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

    @media only screen and (max-width: 375px) {
      font-size: 1.5rem;
      top: 143px;
    }
  }
`;

export default Home;

const ProductSection = css`
  display: flex;
  //width: 100vw;
  width: 1920px;
  box-sizing: border-box;
  padding: var(--Opacity-100, 100px) 0px;

  flex-direction: column;
  align-items: center;
  gap: 24px;
  background: #f9fafb;
`;

const ProductContentWrapper = css`
  border-radius: 20px;
  background: #fff;
  width: 588px;
  height: 218px;
  box-sizing: border-box;

  @media only screen and (max-width: 375px) {
    width: 335px;
    height: 116px;
    padding: 24px;
  }

  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 46px;

  .product_content_area {
    display: flex;
    width: 508px;
    flex-direction: column;
    align-items: flex-end;

    @media only screen and (max-width: 375px) {
      width: 100%;
    }

    .logo_warpper {
      display: flex;
      flex-direction: column;
      align-self: stretch;

      .logo_intro {
        margin-top: 16px;
        color: #1e1e1e;
        text-align: start;

        font-family: Pretendard;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 133.333%;

        @media only screen and (max-width: 375px) {
          display: flex;
          align-items: center;
          justify-content: space-between;

          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
  }
`;

const ProductBannerWrapper = css`
  border-radius: 20px;
  background: linear-gradient(275deg, #e7f1fe 11.44%, #f3f4ff 91.82%);
  display: flex;
  padding: 32px 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  @media only screen and (max-width: 375px) {
    padding: 24px;
  }

  .divider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: inherit;
    @media only screen and (max-width: 375px) {
      flex-flow: column-reverse;
    }

    .left_area {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      @media only screen and (max-width: 375px) {
        align-items: center;
      }

      .text_area {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        @media only screen and (max-width: 375px) {
          align-items: center;
        }

        .title {
          color: #1e1e1e;

          font-family: Pretendard;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 128.125%;

          @media only screen and (max-width: 375px) {
            font-size: 1.125rem;
          }
        }

        .content {
          color: var(--Text-Alternative, #797e86);
          font-family: Pretendard;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 133.333%;

          @media only screen and (max-width: 375px) {
            font-size: 0.813rem;
            text-align: center;
          }

          text-align: start;
        }
      }

      button {
        display: flex;
        width: 160px;
        height: 40px;
        padding: 10px 17px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        border-radius: 4px;
        background: #1e1e1e;

        color: #fff;

        text-align: center;

        font-family: Pretendard;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
    }
    @media only screen and (max-width: 375px) {
      .icon-wrapper {
        svg {
          width: 240px;
          height: 150px;
        }
      }
    }
  }
`;

const BtnIconStyle = css`
  cursor: pointer;
  &:hover {
    g > circle {
      fill: #1c64f2;
      transition: all ease 0.3s;
    }
  }
`;

const HireContainer = css`
  width: inherit;
  position: relative;
  display: flex;
  justify-content: center;
  align-itmes: center;

  .text-area {
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    position: absolute;
    top: 91px;

    @media only screen and (max-width: 375px) {
      top: 56px;
    }
  }

  .title {
    color: #fff;
    font-family: Pretendard;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 1.25rem;
    }
  }

  .sub-title {
    color: var(--Text-Assistive, #d7d7d7);
    text-align: center;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    margin-top: 12px;
    margin-bottom: 40px;

    @media only screen and (max-width: 375px) {
      font-size: 0.813rem;
    }
  }

  .hire-btn {
    cursor: pointer;
    display: inline-flex;
    width: 300px;
    height: 48px;
    min-width: 131px;
    justify-content: center;
    align-items: center;

    color: var(--Text-Normal, #1e1e1e);
    text-align: center;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    border-radius: 4px;
    background: #fff;

    @media only screen and (max-width: 375px) {
      width: 155px;
      height: 36px;
      font-size: 0.875rem;
    }

    &:hover {
      background: #cfd2d8;
    }
    transition: all ease 0.3s;
  }
`;

const BannerCardWrapper = css`
  border-radius: 8px;
  border: 1px solid #002742;
  box-sizing: border-box;

  background: rgba(51, 71, 85, 0.12);

  box-shadow: 0px 4px 40px 0px #031721;
  backdrop-filter: blur(20px);

  display: flex;
  width: 400px;
  height: 149px;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;

  gap: 8px;

  @media only screen and (max-width: 375px) {
    gap: 0px;
    width: 240px;
    height: 75px;
    padding: 16px;
  }

  .card-title {
    color: var(--Dark-Text-Strong, #fff);
    font-family: Pretendard;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    @media only screen and (max-width: 375px) {
      font-size: 0.875rem;
      font-weight: 600;
    }
  }
  .card-content {
    color: #fff;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    @media only screen and (max-width: 375px) {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
`;
